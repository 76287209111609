import React, {useContext} from 'react'
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { generalContext } from "../../contexts/Form/GeneralContext";

const Pagnation = () => {
    
    const {active, setActive} = useContext(generalContext);
    const Navigate =useNavigate();
  
    function Back() {
      Navigate(-1);
      setActive("Prev")
  
    }
  
    function nextPage() {
      Navigate(1);
      setActive("Next")
    }
  return (
    
    <div className="mt-8 flex justify-between">
        <Link to="/scheduler" className="text-sidetextbg ">Open scheduler</Link>
          <div>
            <div className="Pagnation">
              <button onClick ={()=> Back()} className={`w-12 border rounded-l-lg border-navgray ${active=="Prev"?"activeColor":""}`}>Prev</button>
              <button onClick ={()=> setActive("1")} className={`w-8 border border-navgray ${active=="1"?"activeColor":""}`}>1</button>
              <button onClick ={()=> setActive("2")} className={`w-8 border border-navgray ${active=="2"?"activeColor":""}`}>2</button>
              <button onClick ={()=> setActive("3")} className={`w-8 border border-navgray ${active=="3"?"activeColor":""}`}>3</button>
              <button onClick ={()=> setActive("4")} className={`w-8 border border-navgray ${active=="4"?"activeColor":""}`}>4</button>
              <button onClick ={()=> nextPage()} className={`w-12 border border-navgray rounded-r-lg ${active=="Next"?"activeColor":""}`}>Next</button>
            </div>
          </div>
        </div>
  )
}

export default Pagnation