import React, { useContext, useState } from "react";
import NextLayout from "../components/LoggedIn/Layout";
import { ScheduleCalendarContext } from "../contexts/Form/ScheduleCalendarContext";
import Calendar from "../components/FormComponent/Calendar.jsx";


//
import { BiSearchAlt, BiChevronDown } from "react-icons/bi";
import { MdOutlineNavigateNext } from "react-icons/md";
import { HiPlusSm } from "react-icons/hi";

//

const ScheduleCalendar = () => {
  const { workShift, setWorkShift } = useContext(ScheduleCalendarContext);
  // const [searchResult, setSearchResult] = useState(EVENTS);

  return (
    <NextLayout>
      <div className='grid-none cols-none md:grid grid-cols-2 mt-6 px-8 lg:px-0'>
        {/* <div className='custom-select w-full' style={{}}>
          <select
            value={workShift}
            onChange={(e) => setWorkShift(e.target.value)}
            className=' w-full md:w-1/2  h-10 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
          >
            <option className='shadow-md' value={"Midnight"}>
              Mid-night shift
            </option>
            <option value={"Earlyhour"}>Eary-hour shift</option>
            <option value={"Morning"}>Morning shift</option>
            <option value={"Afternoon"}>Afternoon shift</option>
            <option value={"Evening"}>Evening shift</option>
            <option value={"Night"}>Night shift</option>
          </select>
        </div> */}

        

        {/* search */}
        {/* <div className=' flex justify-around'>
          <label className='relative w-1/2 pr-4 '>
            <span className='sr-only '>Search</span>
            <div className='flex'>
              <div className='absolute inset-y-0 left-0 pt-3 pl-2  text-searchicon'>
                <BiSearchAlt className='' />
              </div>
              <input
                onChange={(e) => {
                  const searchKey = e.target.value;
                  if (!searchKey) return setSearchResult(EVENTS);
                  const filtered = EVENTS.filter((info) => {
                    return `${info.title} `.toLowerCase().includes(searchKey.toLowerCase());
                  });
                  setSearchResult(filtered);
                }}
                className=' placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm'
                placeholder='Search'
                type='text'
                name='search'
              />
            </div>
          </label>

          filter

          
          <div className=' w-20 mt-1 hidden lg:block'>
            <button className='w-20  '>
              <HiPlusSm className='w-10 rounded-md h-8 py-1 bg-addred border text-center text-white' />
            </button>
          </div>
        </div> */}
      </div>
       <Calendar/>
      <div className='pr-12 px-12 md:px-0   w-50'>
       
      </div>
    </NextLayout>
  );
};

export default ScheduleCalendar;
