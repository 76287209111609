import { useContext } from "react";
import { DataContext } from "../contexts/Data/DataContext";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import useNotification from "./useNotification";
import { getPatients, getPatientData, getAppointment } from "../services/patients/patients";
import { useEffect } from "react";
import { useState } from "react";
import { doctorAccountDetails } from "../utils/appwrite";

const usePatient = () => {
  const { allPatients, patient, setAllPatients, setPatient, setAllAppointments, allAppointments } = useContext(DataContext);
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError } = useNotification();

  const getAllPatientsList = async () => {
    if (allPatients) return;
    setShowLoader(true);
    try {
      getPatients().then((res) => {
        setAllPatients(res?.documents);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const getAllPatientsAppoinments = async () => {
    // if (allAppointments) return;
    setShowLoader(true);
    try {
      getAppointment().then((res) => {
        setAllAppointments(res?.documents);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };


  const useGetDoctorsInfo = (id) => {
   const [doctor, setDoctor] = useState()

   useEffect(() => {
      const getDoctorsInfo = async () => {
        const doctors = await doctorAccountDetails(id)
        console.log("doctors====>", doctors.documents?.[0])
        setDoctor(doctors.documents?.[0])
      }
      getDoctorsInfo()
   }, [id])

   return { doctor }
  }


  const getPatientByNumber = async (number, setError, setMessages) => {
    setShowLoader(true);
    try {
      getPatientData(number).then((res) => {
        setPatient(res);
        if (res?.documents?.length === 0) {
          setError(true);
          setMessages("No patient found with this number");
        }
      });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  return {
    patient,
    setPatient,
    getAllPatientsList,
    getPatientByNumber,
    getAllPatientsAppoinments,
    allPatients,
    setAllPatients,
    useGetDoctorsInfo
  };
};

export default usePatient;
