import React, { useState, useContext } from "react";
//
import { Link, useLocation } from "react-router-dom";

import { TbLogout } from "react-icons/tb";

import { LogoutSvg } from "../../assets/SVG.js";
import { OverviewSvg } from "../../assets/SVG.js";
import { Scheduleicon } from "../../assets/SVG.js";
import { Patienticon } from "../../assets/SVG.js";
import { Chaticon } from "../../assets/SVG.js";
import { Settingicon } from "../../assets/SVG.js";

//
import { Pinkoverviewsvg, Pinkchatsvg, Pinkpatientsvg, Pinkschedulesvg, Pinksettingsvg } from "../../assets/SVG.js";

import useAuth from "../../hooks/useAuth.jsx";

const Sidebar = ({ showSidebar, toggleSidebar }) => {
  // route
  const location = useLocation();
  const { logoutUser } = useAuth();

  return (
    <div className=' py-3 md:py-12 pr-8 bg-navbg shadow-sm h-full hidden lg:block '>
      {/* overview */}
      <div className='mt- mb-6'>
        <Link to='/' className='mb-5'>
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${location.pathname === "/" ? "activeTab" : "text-grayon"}`}>
            {location.pathname === "/" ? <Pinkoverviewsvg styles='w-8 bg-sidebg' /> : <OverviewSvg className='w-8 stroke-gray-500 ' />}

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}>Overview</span>
          </div>
        </Link>
      </div>

      {/* schedule */}
      <div className='mt-6 mb-6'>
        <Link to='/scheduler' className='mb-5'>
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${location.pathname === "/scheduler" ? "activeTab" : "text-grayon"}`}>
            {location.pathname === "/scheduler" ? <Pinkschedulesvg styles='w-8 bg-sidebg' /> : <Scheduleicon className='w-8 stroke-gray-500 ' />}

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}>Schedule</span>
          </div>
        </Link>
      </div>

      {/* patient */}
      <div className='mt-6 mb-6'>
        <Link to='/patient' className='mb-5'>
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${location.pathname === "/patient" ? "activeTab" : "text-grayon"}`}>
            {location.pathname === "/patient" ? <Pinkpatientsvg styles='w-8 bg-sidebg' /> : <Patienticon className='w-8 stroke-gray-500 ' />}

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}>Patients</span>
          </div>
        </Link>
      </div>

      {/* chat */}
      <div className='mt-6 mb-6'>
        <Link to='/chat' className='mb-5'>
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${location.pathname === "/chat" ? "activeTab" : "text-grayon"}`}>
            {location.pathname === "/chat" ? <Pinkchatsvg styles='w-8 bg-sidebg' /> : <Chaticon className='w-8 stroke-gray-500 ' />}

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}>Chats</span>
          </div>
        </Link>
      </div>

      {/* setting */}
      <div className='mt-6 mb-6'>
        <Link to='/settings' className='mb-5'>
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${location.pathname === "/settings" ? "activeTab" : "text-grayon"}`}>
            {location.pathname === "/settings" ? (
              <div className='bg-sidebg'>
                <Pinksettingsvg className='w-8 ' />
              </div>
            ) : (
              <Settingicon alt='' className='w-8' />
            )}

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}>Settings</span>
          </div>
        </Link>
      </div>

      {/* logout */}
      <div className='mt-6 mb-6'>
        <button
          onClick={() => {
            logoutUser();
          }}
        >
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8`}>
            <LogoutSvg alt='' className='w-8' />

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  text-grayon `}>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
