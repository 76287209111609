import { useContext, useEffect, useState } from "react";
import { DataContext } from "../contexts/Data/DataContext";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import useNotification from "./useNotification";
import { getAppointment } from "../services/patients/patients";
import { UserContext } from "../contexts/User/UserContext";
import { getAppointmentByID } from "../utils/appwrite";

const useAppointment = () => {
  const { allAppointments, setAllAppointments, token, setToken } = useContext(DataContext);
  const { doctorDetails } = useContext(UserContext);
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError } = useNotification();

  const getAllAppointmentList = async () => {
    setShowLoader(true);
    if (allAppointments) return;
    try {
      getAppointment().then((res) => {
        setAllAppointments(res?.documents);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const getToken = async (channel) => {
    if (!doctorDetails) return;
    const response = await fetch(`https://doci-node-server.vercel.app/rtc/${channel}/publisher/uid/1`);
    const data = await response.json();
    setToken(data.rtcToken);
    return data.rtcToken;
  };

  return {
    getAllAppointmentList,
    getToken,
    token,
    doctorDetails,
  };
};

export default useAppointment;

export function useGetAppointment(id) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("id/////", id);
  useEffect(() => {
    setLoading(true);
    getAppointmentByID(id)
      .then((res) => {
        console.log("res?.documents?.[0]", res?.documents?.[0]);
        setData(res?.documents?.[0]);
      })
      .catch((error) => console.log(error))
      .finally(setLoading(false));
  }, []);

  return { data, loading };
}
