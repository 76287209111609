import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Ellipse from "../photos/ellipse.png";

//
import { ViewProfileicon } from "../../assets/SVG.js";
import { Profilelogout } from "../../assets/SVG.js";
import { Profilesetting } from "../../assets/SVG.js";
import { Manageicon } from "../../assets/SVG.js";

//
import { showContext } from "../../contexts/Form/ShowContext";
import { UserContext } from "../../contexts/User/UserContext";
import useAuth from "../../hooks/useAuth.jsx";
import ReactImageFallback from "react-image-fallback";
import defaultImage from "../photos/default_user.png";

const Dropdown = () => {
  const { isShown, setIsShown } = useContext(showContext);
  const { doctorDetails } = useContext(UserContext);
  const location = useLocation();
  const { logoutUser } = useAuth();

  let userImage =
    doctorDetails?.image ||
    `https://ui-avatars.com/api/?name=${
      doctorDetails?.first_name + " " + doctorDetails?.last_name
    }&background=B21E14&color=fff`;

  return (
    <div>
      {/* Profile drop down */}
      {isShown ? (
        <div className="absolute z-50 bg-white right-8" style={{ top: "57px" }}>
          <div className="">
            <div className="bg-red-600 w-60 h-1"></div>
            <div className="border border-navgray shadow-md  rounded-lg w-full flex  items-center ">
              <div className="pb-4 ">
                <div className="bg-pink-200 w-60">
                  <div className="flex mb-4 px-4">
                    <div className="w-8">
                      <div className="avatar">
                        <div className="mask mask-squircle w-8 h-10">
                          <ReactImageFallback
                            onClick={() => setIsShown(!isShown)}
                            src={userImage}
                            fallbackImage={defaultImage}
                            initialImage={defaultImage}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-[12px] ml-3 mt-1.5">
                        {doctorDetails?.first_name +
                          " " +
                          doctorDetails?.last_name}
                      </div>
                      <div className="text-grayon text-[10px] ml-3">
                        {doctorDetails?.email}
                      </div>
                    </div>
                  </div>
                </div>

                {/* view profile */}
                {/* <div className="flex mb-4 px-4">
                  <div className="w-8">
                    <ViewProfileicon />
                  </div>

                  <div>
                    <div className="text-grayon text-sm ml-3">View Profile</div>
                  </div>
                </div> */}

                {/* Account settings */}
                <Link to="/settings" className="flex mb-6 px-4">
                  <div className="">
                    <Profilesetting />
                  </div>

                  <div>
                    <div className="text-grayon text-sm ml-5">
                      Account Settings
                    </div>
                  </div>
                </Link>

                {/* Logout */}
                <div className="flex px-4"
                 onClick={() => {
                  logoutUser();
                }}
                >
                  <div className="">
                    <Profilelogout />
                  </div>

                  <div
                   
                  >
                    <div className=" cursor-pointer text-grayon text-sm ml-5">
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dropdown;
