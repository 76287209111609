import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/LoggedIn/Layout.jsx";
import { MdOutlineNavigateNext } from "react-icons/md";
import Largetextarea from "../components/FormComponent/Largetextarea.jsx";
import FormInput from "../components/FormComponent/Input.jsx";
import TextArea from "../components/FormComponent/InputArea.jsx";
import { useForm } from "react-hook-form";
import useConsultations from "../hooks/useConsultations";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataContext } from "../contexts/Data/DataContext";
import "react-datepicker/dist/react-datepicker.css";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import useNotification from "../hooks/useNotification";
import usePatient from "../hooks/usePatient.jsx";

import { Client, Account, Databases, Query, ID } from "appwrite";
import { useEffect } from "react";
import { SecurityUpdate } from "@mui/icons-material";

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const ConsultationsCollection = "639317529c24aa63551e";
const account = new Account(client);

const DB = new Databases(client);

// get patient by id
 async function getConsultationByPatientId(id) {
  console.log(`patientID`, id);
  const data = await DB.listDocuments(DBID, ConsultationsCollection, [
    Query.equal("patientID", id),
  ]);
  return data?.documents[0];
}

// window.getConsultation = async (key, value) => {
//   const data = await DB.listDocuments(DBID, ConsultationsCollection, [
//     Query.equal(key, value),
//   ]);
//   return data?.documents[0];
// };

const Consultation = (props) => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const { patient } = usePatient();
  const {consultations, setConsultations } = useContext(DataContext);
  const { createConsultationInfo } = useConsultations();
 

  const [selectedDate, setSelectedDate] = useState(new Date());

  const { setShowLoader } = useContext(UtilityContext);
  const [value, setValue] = useState("");
  const { handleError, handleSuccess } = useNotification();
  const [dataSaved, setDataSaved] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { register, reset, handleSubmit, formState } = useForm(formOptions);
 
 

  useEffect(() => {
    if (!patient) navigate("/patient-information");
    getConsultationByPatientId(patient?.documents[0].$id).then((data) => {
      setConsultations(data);
    });
  }, [dataSaved, patient]);

  const saveData = async (data) => {
    console.log({ data });
    try {
    
      const result2 = await DB.createDocument(
        DBID,
        ConsultationsCollection,
        ID.unique(),
        {
          ...data,
          patientID: patient?.documents[0].$id,
        }
      );
      console.log("Data saved:", result2);
      setDataSaved(true);

      if (!result2) throw new Error("failed");

      console.log("sucessful");

      // await createOrganizationUser({ userID: result1.$id, OrgUserID: result2.$id })
      // handleSuccess("Organization created successfully");
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const submitHandler = async (e) => {
    console.log({event:e})
    e.preventDefault()
    console.log('data is => ',consultations)
    // event.preventDefault();
    console.log({ patient });

    if (consultations?.patientID) {
      // Update data
      updateData(consultations);
    } else {
      // Save data
      saveData(consultations);
    }
  };

  const updateData = async (data) => {
    try {
      console.log(`updating... patient ${patient?.documents[0].$id}`);
      const response = await DB.updateDocument(
        DBID,
        ConsultationsCollection,
        consultations.$id,
        {
          ...data,
          $collectionId: undefined,
          $createdAt: undefined,
          $databaseId: undefined,
          $id: undefined,
          $permissions: undefined,
          $updatdAt: undefined,
        } 
      );
      console.log("Data updated:", response);
      setUpdated(true);
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Saved sucessfully");
    }
  };

  
  const [isChecked1, setIsChecked1] = useState("");
  const [isChecked2, setIsChecked2] = useState("");
  const [isChecked3, setIsChecked3] = useState("");
  const [isChecked4, setIsChecked4] = useState("");
  const [isChecked5, setIsChecked5] = useState("");
  const [isChecked6, setIsChecked6] = useState("");
  const [isChecked7, setIsChecked7] = useState("");
  const [isChecked8, setIsChecked8] = useState("");
  const [isChecked9, setIsChecked9] = useState("");
  const [isChecked10, setIsChecked10] = useState("");
  const [isChecked11, setIsChecked11] = useState("");
  const [isChecked12, setIsChecked12] = useState("");
  const [isChecked13, setIsChecked13] = useState("");
  const [isChecked14, setIsChecked14] = useState("");
  const [isChecked15, setIsChecked15] = useState("");
  const [isChecked16, setIsChecked16] = useState("");

  const handleCheckbox1Change = () => {
    if (!isChecked1) {
      setIsChecked1(true);
      setIsChecked2(false);
    }
  };

  const handleCheckbox2Change = () => {
    if (!isChecked2) {
      setIsChecked2(true);
      setIsChecked1(false);
    }
  };

  const handleCheckbox3Change = () => {
    if (!isChecked3) {
      setIsChecked3(true);
      setIsChecked4(false);
    }
  };

  const handleCheckbox4Change = () => {
    if (!isChecked4) {
      setIsChecked4(true);
      setIsChecked3(false);
    }
  };

  const handleCheckbox5Change = () => {
    if (!isChecked5) {
      setIsChecked5(true);
      setIsChecked6(false);
    }
  };

  const handleCheckbox6Change = () => {
    if (!isChecked6) {
      setIsChecked6(true);
      setIsChecked5(false);
    }
  };

  const handleCheckbox7Change = () => {
    if (!isChecked7) {
      setIsChecked7(true);
      setIsChecked8(false);
    }
  };

  const handleCheckbox8Change = () => {
    if (!isChecked8) {
      setIsChecked8(true);
      setIsChecked7(false);
    }
  };

  const handleCheckbox9Change = () => {
    if (!isChecked9) {
      setIsChecked9(true);
      setIsChecked10(false);
    }
  };

  const handleCheckbox10Change = () => {
    if (!isChecked10) {
      setIsChecked10(true);
      setIsChecked9(false);
    }
  };

  const handleCheckbox11Change = () => {
    if (!isChecked11) {
      setIsChecked11(true);
      setIsChecked12(false);
    }
  };

  const handleCheckbox12Change = () => {
    if (!isChecked12) {
      setIsChecked12(true);
      setIsChecked11(false);
    }
  };

  const handleCheckbox13Change = () => {
    if (!isChecked13) {
      setIsChecked13(true);
      setIsChecked14(false);
    }
  };

  const handleCheckbox14Change = () => {
    if (!isChecked14) {
      setIsChecked14(true);
      setIsChecked13(false);
    }
  };

  const handleCheckbox15Change = () => {
    if (!isChecked15) {
      setIsChecked15(true);
      setIsChecked16(false);
    }
  };

  const handleCheckbox16Change = () => {
    if (!isChecked16) {
      setIsChecked16(true);
      setIsChecked15(false);
    }
  };

  const changeHandler = (e) => {
    window.e = e;
    if (
      e.target.nodeName === "INPUT" &&
      e.target.getAttribute("type") === "checkbox"
    ) {
      console.log(`${e.target.name} is ${e.target.checked}`);
      setConsultations({
        ...consultations,
        [e.target.name]: e.target.checked,
      });
    } else
      setConsultations({
        ...consultations,
        [e.target.name]: e.target.value,
      });
  };

  console.log("this is consultation page ", props.setActivepage);
  return (
    <>
      <Layout setActivepage={props.setActivepage} showHeading>
        <form onSubmit={submitHandler}>
          <div className="justify-center md:justify-start z-5 pb-50">
            <div className=" grid-none cols-none md:grid grid-cols-2">
              {/* left side */}

              {/* info */}
              <div className="px-8">
                <h1 className="block text-sm  font-medium  text-consultblack">
                  Previous consultation
                </h1>
                <h2 className="text-grayon text-sm ">
                  The report below was submitted by Doci Virtual Care Physicians
                </h2>

                {/*First Chronic Disease  Card*/}
                <div className="mt-4">
                  <div className="flex justify-between w-full lg:w-1/2  rounded-t-lg h-8 bg-pink-200 items-center border border-navgray">
                    <div className="text-xs md:text-sm ml-4 text-arcgold">
                      Chronic diseases
                    </div>

                    <div>
                      <MdOutlineNavigateNext
                        size={20}
                        className="text-arcgold"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <div className="  h-fit border border-navgray rounded-b-lg">
                      <div className="text-[12px] ml-4 text-consultblack font-bold mb-1 mt-1">
                        Asthma
                      </div>
                      <div className="text-nextgray flex justify-between text-[10px] md:text-[12px] px-4 mb-1">
                        <div>Diagnosed by Dr. Mathew Muna</div>
                        <div>15-April-2022</div>
                      </div>
                      <div className="text-[14px] ml-4 text-consultblack font-bold">
                        Prescription
                      </div>
                      <div className="text-nextgray flex justify-between text-[10px] md:text-[12px] px-4 mb-6">
                        Inhaler and Amolicinin
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* right screen */}
              <div className="w-full lg:w-1/2 px-8">
                {/*Second Chronic Disease  Card*/}
                <div className=" pt-20 lg:pt-14 ">
                  <div className="flex justify-between   rounded-t-lg h-8 bg-chronicbg items-center border border-navgray">
                    <div className="text-xs md:text-sm ml-4 text-dgreen">
                      Chronic diseases
                    </div>

                    <div>
                      <MdOutlineNavigateNext
                        size={20}
                        className="text-dgreen"
                      />
                    </div>
                  </div>
                  <div className=" h-fit border border-navgray  rounded-b-lg">
                    <div className="text-[12px] ml-4 text-consultblack font-bold mb-1 mt-1">
                      Asthma
                    </div>
                    <div className="text-nextgray flex justify-between text-[10px] md:text-[12px] px-4 mb-1">
                      <div>Diagnosed by Dr. Mathew Muna</div>
                      <div>15-April-2022</div>
                    </div>
                    <div className="text-[12px] ml-4 text-consultblack font-bold">
                      Prescription
                    </div>
                    <div className="text-nextgray flex justify-between text-[10px] md:text-[12px] px-4 mb-6">
                      <div>Inhaler and Amolicinin</div>
                      <div>15-April-2022</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-8 ">
              <div className="text-grayon text-[16px] pt-8 pb-3">
                My consultation
              </div>

              {/*input  presenting complaints */}
              <div className="text-consultblack text-[14px] font-medium">
                Presenting Complaints
              </div>
              <Largetextarea
                name={"complaints"}
                value={consultations?.complaints}
                defaultValue={consultations?.complaints}
                register={() => {}}
                onChange={changeHandler}
                type="text"
                rols={5}
                cols={150}
              />
              {/* input diagnosis  */}
              <div className="text-consultblack text-[14px] font-medium">
                Diagnosis
              </div>
              {/* <Largetextarea value={complaints} onChange={(e) => setDiagnosis(e.target.value)} */}
              <div>
                <div>
                  <Largetextarea
                    name={"diagnosis"}
                    // value={consultations?.diagnosis}
                    defaultValue={consultations?.diagnosis}
                    register={() => {}}
                    onChange={changeHandler}
                    rols={5}
                    cols={150}

                    // className=" w-96 md:w-full gap-2 focus-none  border-grayon rounded-md mb-2 border drop-shadow-sm   placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2"
                  />
                </div>
              </div>

              {/* system review */}
              <div className="text-grayon text-[16px] pt-8 pb-3">
                Systemic review
              </div>

              {/* table */}
              <div className="w-full h-fit border-none">
                <div className="overflow-x-auto w-full">
                  <table className="table w-full">
                    {/* <!-- head --> */}
                    <thead>
                      <tr className="bg-white text-consultblack sm:text-xs lg:text-sm font-medium">
                        <th className="bg-white"></th>

                        <th className="bg-white ">Normal/Not Normal</th>
                        <th className="bg-white ">Notes</th>
                      </tr>
                    </thead>
                    <tbody className="sm:text-xs lg:text-sm w-70 md:w-full">
                      {/* row 1 */}
                      {/* sickness name */}

                      <tr>
                        <td>
                          Musculoskeletal (Mobility, swelling, stiff joint)
                        </td>

                        <>
                          <td>
                            <input
                              type="checkbox"
                              name={"sensoryOne"}
                              // value={"sensoryOne"}
                              defaultValue={consultations?.sensoryOne}
                              onChange={changeHandler}
                              value={consultations?.sensoryOne}
                              checked={consultations?.sensoryOne}
                              // checked={isChecked1}
                              // checked={handleCheckbox1Change}
                              // onChange={handleCheckbox1Change}
                              className=" checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                              style={{
                                width: "60px",
                                height: "30px",
                              }}
                            />
                          </td>
                         
                        </>

                        {/* not normal */}
                        <td>
                          <TextArea
                            // add Notes
                            name={"sensoryNote"}
                            // value={consultations?.sensoryNote}
                            defaultValue={consultations?.sensoryNote}
                            register={() => {}}
                            onChange={changeHandler}  
                            type="text"
                            //value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row 2 */}
                      <tr>
                        <td>
                          Integumentary Rashes, irritation, pale, skin changes
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            name={"integumentaryOne"}
                            // value={"integumentaryOne"}
                            defaultValue={consultations?.integumentaryOne}
                            //{...register("integumentaryOne")}
                            onChange={changeHandler}
                            checked={consultations?.integumentaryOne}
                            // onChange={handleCheckbox3Change}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                        

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"integumentaryNote"}
                            // value={consultations?.integumentaryNote}
                            defaultValue={consultations?.integumentaryNote}
                            register={() => {}}
                            onChange={changeHandler}
                            // values={"integumentaryNote"}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row3 */}
                      <tr>
                        <td>
                          Circulatory (chest pain, waking at night with
                          breathlessness,leg swelling, cough)
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            defaultValue={consultations?.circulatoryOne}
                            name={"circulatoryOne"}
                            // values={"circulatoryOne"}
                            //{...register("circulatoryOne")}
                            checked={consultations?.circulatoryOne}
                            // checked={isChecked5}
                            // onChange={handleCheckbox5Change}
                            onChange={changeHandler}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                       

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"circulatoryNote"}
                            // value={consultations?.circulatoryNote}
                            defaultValue={consultations?.circulatoryNote}
                            register={() => {}}
                            // values={"circulatoryNote"}
                            onChange={changeHandler}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row4 */}
                      <tr>
                        <td>
                          Respiratory (Shortness of breath, waking at night
                          breathlessness, leg swelling, cough)
                        </td>
                        <>
                          <td>
                            <input
                              type="checkbox"
                              name={"respiratoryOne"}
                              defaultValue={consultations?.respiratoryOne}
                              // values={"respiratoryOne"}
                              //{...register("respiratoryOne")}

                              // checked={elem.normal ? "checked" : ""}
                              checked={consultations?.respiratoryOne}
                              // onChange={handleCheckbox7Change}
                              onChange={changeHandler}
                              className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                              style={{
                                width: "60px",
                                height: "30px",
                              }}
                            />
                          </td>
                         
                        </>
                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"respiratoryNote"}
                            // value={consultations?.respiratoryNote}
                            defaultValue={consultations?.respiratoryNote}
                            onChange={changeHandler}
                            register={() => {}}
                            // values={"respiratoryNote"}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row5 */}
                      <tr>
                        <td>Dental (Dentures)</td>
                        <td>
                          <input
                            type="checkbox"
                            name={"dentalOne"}
                            defaultValue={consultations?.dentalOne}
                            // values={"dentalOne"}
                            //{...register("dentalOne",)}
                            // checked={elem.normal ? "checked" : ""}
                            checked={consultations?.dentalOne}
                            // onChange={handleCheckbox9Change}
                            onChange={changeHandler}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                       

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"dentalNote"}
                            // value={consultations?.dentalNote}
                            defaultValue={consultations?.dentalNote}
                            // values={"dentalNote"}
                            register={() => {}}
                            onChange={changeHandler}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row6 */}
                      <tr>
                        <td>Psychosocial (Hallucinations, delusions)</td>
                        <td>
                          <input
                            type="checkbox"
                            name={"psychosocialOne"}
                            defaultValue={consultations?.psychosocialOne}
                            // values={"psychosocialOne"}
                            //{...register("psychosocialOne")}
                            // checked={elem.normal ? "checked" : ""}
                            checked={consultations?.psychosocialOne}
                            // onChange={handleCheckbox11Change}
                            onChange={changeHandler}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                       

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"psychosocialNote"}
                            // values={"psychosocialNote"}
                            value={consultations?.psychosocialNote}
                            defaultValue={consultations?.psychosocialNote}
                            register={() => {}}
                            type="text"
                            // value={elem.notes}
                            onChange={changeHandler}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row7 */}
                      <tr>
                        <td>Nutrition (Diet, weight change, swallowing)</td>
                        <td>
                          <input
                            type="checkbox"
                            name={"nutritionOne"}
                            defaultValue={consultations?.nutritionOne}
                            // values={"nutritionOne"}
                            //{...register("nutritionOne")}
                            // checked={elem.normal ? "checked" : ""}
                            checked={consultations?.nutritionOne}
                            // onChange={handleCheckbox13Change}
                            onChange={changeHandler}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                       

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"nutritionNote"}
                            // values={"nutritionNote"}
                            // value={consultations?.nutritionNote}
                            defaultValue={consultations?.nutritionNote}
                            register={() => {}}
                            onChange={changeHandler}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>

                      {/* row8 */}
                      <tr>
                        <td>GIT (Constipation, incontinence, diarrhoea)</td>
                        <td>
                          <input
                            type="checkbox"
                            name={"gitOne"}
                            // values={"gitOne"}
                            defaultValue={consultations?.gitOne}
                            //{...register("gitOne")}
                            // checked={elem.normal ? "checked" : ""}
                            checked={consultations?.gitOne}
                            // onChange={handleCheckbox15Change}
                            onChange={changeHandler}
                            className="checked accent-white border-nextred rounded-md mb-2 border drop-shadow-sm text-addred focus:ring-transparent"
                            style={{
                              width: "60px",
                              height: "30px",
                            }}
                          />
                        </td>
                       

                        {/* not normal */}
                        <td>
                          <TextArea
                            name={"gitNote"}
                            // values={"gitNote"}
                            // value={consultations?.gitNote}
                            defaultValue={consultations?.gitNote}
                            onChange={changeHandler}
                            type="text"
                            // value={elem.notes}
                            required="yes"
                            size="w-3/2 md:w-3/2  lg:w-3/2 rows={10} cols={10} placeholder:text-center text-start"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* input prescribed drug */}
              <div className="mt-12">
                <div className="text-consultblack text-[14px] font-medium">
                  Prescribe Drugs based on diagnosis above
                </div>
                {/* <Largetextarea value={complaints} onChange={(e) => setDiagnosis(e.target.value)} */}
                <div>
                  <div>
                    <Largetextarea
                      name={"prescribedrug"}
                      // values={"prescribedrug"}
                      defaultValue={consultations?.prescribedrug}
                      value={consultations?.prescribedrug}
                      register={() => {}}
                      onChange={changeHandler}
                      // value={prescribedrug}
                      type="text"
                      rols={5}
                      cols={150}

                      // className=" w-96 md:w-full gap-2 focus-none  border-grayon rounded-md mb-2 border drop-shadow-sm   placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2"
                    />
                  </div>
                </div>

                {/*  */}
                <div className="text-consultblack text-[14px] font-medium mt-12">
                  Recommend Test
                </div>
                {/* <Largetextarea value={complaints} onChange={(e) => setDiagnosis(e.target.value)} */}
                <div>
                  <div>
                    <Largetextarea
                      name={"rtest"}
                      // values={"rtest"}
                      value={consultations?.rtest}
                      defaultValue={consultations?.rtest}
                      register={() => {}}
                      onChange={changeHandler}
                      type="text"
                      // value={rtest}
                      rols={5}
                      cols={150}

                      // className=" w-96 md:w-full gap-2 focus-none  border-grayon rounded-md mb-2 border drop-shadow-sm   placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2"
                    />
                  </div>
                </div>

                <div className="text-consultblack text-[14px] font-medium mt-12 mb-30">
                  Follow up appointment
                </div>
                <FormInput
                  name={"date"}
                  defaultValue={consultations?.date}
                  // handleChange={()=>{}}
                  onChange={changeHandler}
                  // value={"date"}
                  value={consultations?.date}
                  // selected={selectedDate}
                  // onChange={(date) => setSelectedDate(date)}
                  // onChange={(date) => {
                  //   console.log('New date selected:', date);
                  //   setSelectedDate(date);
                  //   setValue('date', date, { shouldValidate: true });
                  // }}
                  // //{...register('date')}

                  type="date"
                  placeholder="DD/MM/YYYY"
                  required="yes"
                  size=" md:w-3/5"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end pr-12 pb-32">
            <button
              type="submit"
              // onClick={() => console.log("clicked")}
              className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
            >
              Save
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Consultation;
