import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Frame from "../components/photos/frame.png"
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases } from "appwrite";
import Doctorimg from "../components/photos/doctorimg.jpg";
import logo from "../components/photos/logo.png";
import useNotification from "../hooks/useNotification";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { client } from "../utils/appwrite";
import { UserContext } from "../contexts/User/UserContext";

const PasswordRecoveryConfirmation = () => {
  const [isLoading, setIsLoading] = useState(false);


  const { handleError, handleSuccess } = useNotification();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showNext, setShownext] = useState(false);

  const navigate = useNavigate();
  let userObj = useContext(UserContext);
  let location = window.location;

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email is required")
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
    confirmpassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password")
      .oneOf([Yup.ref("password")], "The passwords do not match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const account = new Account(client);

  const queryParams = new URLSearchParams(window.location.search);
  const USER_ID = queryParams.get("userId");
  const SECRET = queryParams.get("secret");

  const submitForm = async (data) => {
    console.log("data is", data);
    setLoading(true);
    try {
      const res = await account.updateRecovery(
        USER_ID,
        SECRET,
        data.password,
        data.confirmpassword,
      );
      console.log(res);
      setLoading(false);
      window.location.href = `${window.location.origin}/login`;
    } catch (error) {
      handleError(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="h-full overflow-y-hidden">
    <div className="lg:flex h-full ">
      {/* left screen */}
      <div className="h-screen bg-mygray lg:w-[258%] lg:block hidden relative">
        {/* logo */}
        <div className="absolute w-96">
          <img src={Frame} alt="" />
        </div>

        {/*  */}
        <div className="">
          <div className="h-screen">
            <img
              style={{}}
              src={Doctorimg}
              alt=""
              srcSet=""
              className="h-screen"
            />
          </div>
        </div>
      </div>

        {/* right screen */}
      
        <div className="w-full sm:w-full lg:1/5  h-full sm:text-center  lg:text-start px-12 md:px-8 lg:px-12">
          {/* logo */}
          <div className="flex justify-center pt-24">
            <div className="w-24 ">
              <img src={logo} alt="" />
            </div>
          </div>

            <div className="font-sans font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0">
              {" "}
              DociHealthcare!{" "}
            </div>
            <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">
              Update password here
            </div>

            {/* form */}

            {/* <Loginform/> */}
            {/* form */}

            <form onSubmit={handleSubmit(submitForm)}>
              <div className="mt-8 relative">
                <p className="text-[#666666] text-[15px] font-bold">
                  New password
                </p>
                <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">
                  Please enter new password here
                </p>
                <input
                  {...register("password")}
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="***********"
                  width="w-[350px]"
                  height="h-[45px]"
                  className="mt-1 block w-full h-14 rounded-md text-left pl-2 text-xl  border-bordergray border    sm:text-sm"
                />
                <div
                  className="absolute top-[4.5rem] right-5 text-[18px]"
                  onClick={() => setShow((prev) => !prev)}
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
                <p className="text-[13px] mt-2 text-red-400">
                  {errors.password?.message}
                </p>
              </div>

              <div className="mt-8 relative">
                <p className="text-[#666666] text-[15px] font-bold">
                  Retype New password
                </p>
                <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">
                  Please retype new password here
                </p>
                <input
                  {...register("confirmpassword")}
                  name="confirmpassword"
                  type={showNext ? "text" : "confirmpassword"}
                  placeholder="***********"
                  width="w-[350px]"
                  height="h-[45px]"
                  className="mt-1 block w-full h-14 rounded-md text-left pl-2 text-xl  border-bordergray border    sm:text-sm"
                />
                <div
                  className="absolute top-[4.5rem] right-5 text-[18px]"
                  onClick={() => setShownext((prev) => !prev)}
                >
                  {showNext ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
                <p className="text-[13px] mt-2 text-red-400">
                  {errors.confirmpassword?.message}
                </p>
              </div>

              <button
                type="submit"
               
                disabled={loading}
                className=" bg-gradient-to-r from-red-500  via-red-600 to-red-700 inline-flex justify-center w-full h-12 rounded-lg text-white py-3"
              >
                 {loading ? (
                  <>
                    <span>Loading...</span>
                    <div className="ld ld-ring ld-spin"></div>
                  </>
                ) : (
                  <>
                    <span>Recover</span>
                  </>
                )}
              </button>

              
            </form>

            {/*  */}
          </div>
        
      
      </div>
    </div>
  );
};

export default PasswordRecoveryConfirmation;
