import React, { useContext, useEffect, useState } from "react";
import NextLayout from "../components/LoggedIn/Layout";
import { Cards } from "../components/FormComponent/Cards.jsx";
import { UserContext } from "../contexts/User/UserContext";
import { DataContext } from "../contexts/Data/DataContext";
import AppointmentModal from "../components/LoggedIn/AppointmentModal"; 
import moment from "moment";



  // Get the time of day

 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Appointment = () => {
  const { doctorDetails } = useContext(UserContext);
  
  const { showModalOne, setShowModalOne } = useContext(DataContext);


  


  

  return (
    <>
      <NextLayout>
        <div className='flex justify-between'>
          <div className='pt-4 pl-12  md:pl-0 lg:pr-8 md:pt-12 text-[19px] md:text-[24px]  md:text-start text-nextgray font-bold '>
          Hello Dr. {doctorDetails?.first_name}
          </div>
          <div className='pr-8 md:pt-12 text-[14px] md:text-[24px] text-end md:text-start text-nextgray font-bold '>
            <div className="py-6 md:py-0">
            <button className='md:p-2  text-sm h-12 md:h-10 border border-red-500 text-nextgray bg-white hover:bg-nextgray hover:text-white rounded-md'>
              <div className='items-center justify-center'>
                <a href='/patient-information' className='font-bold '>
                  Start a Consultation
                </a>
              </div>
            </button>
            </div>
          </div>
        </div>
        <div>
          <Cards />
        </div>

        {/* The modal for accepting appointment invitations lays here */}
        {showModalOne ? <AppointmentModal  /> : null}
        <ToastContainer />
      </NextLayout>
    </>
  );
};

export default Appointment;
