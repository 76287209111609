import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import Cancel from "../FormComponent/Cancel"

//
import { Pinkoverviewsvg } from "../../assets/SVG";
import { OverviewSvg } from "../../assets/SVG";

const Slidesidebar = (props) => {
  // route
  const location = useLocation();
  const [close, setClose] = useState(false)

  return (
    
    <div>
      <div className='drawer '>
        <input id='my-drawer' type='checkbox' className='drawer-toggle' />
        <div className='drawer-content'>
          {/* <!-- Page content here --> */}
          {props.children}
        </div>
        <div className='drawer-side'>
          <label htmlFor='my-drawer' className='drawer-overlay'></label>
          <div className='menu p-4 w-80 bg-base-100  text-center italic cursor-pointer text-addred '>
            {/* <!-- Sidebar content here --> */}
            {/* overview */}
            <div className="flex justify-end pr-6 pt-6">
            <Cancel className="w-24"/>
             </div>
            <Link to='/'>
              <div className='text-lg pb-4 pt-8' >
                <a >Overview</a>
              </div>
            </Link>

            {/* schedule */}
            <Link to='/scheduler'>
              <div className='text-lg py-4'>
                <a>Schedule</a>
              </div>
            </Link>

            {/* patients */}
            <Link to='/patient'>
              <div className='text-lg py-4'>
                <a>Patients</a>
              </div>
            </Link>

            {/* chat */}
            <Link to='/chat'>
              <div className='text-lg py-4'>
                <a>Chats</a>
              </div>
            </Link>

            {/* settings */}
            <Link to='/settings'>
              <div className='text-lg py-4'>
                <a>Settings</a>
              </div>
            </Link>

            {/* logout */}
            <Link to='/login'>
              <div className='text-lg py-4'>
                <a>Logout</a>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidesidebar;
