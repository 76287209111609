import React from "react";
import Photo from "../components/photos/frame.png";
import Frame from "../components/photos/frame.png"
import Logo from "../components/photos/logo.png";
import Doctorimg from "../components/photos/doctorimg.jpg";
import Illustration from "../components/photos/illustration.png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";


//
import Registerform from "../components/FormComponent/Registerform";

const Signup = (props) => {
  return (
    <div className='h-full overflow-y-hidden'>
     <div className="lg:flex h-full ">
        {/* left screen */}
        <div className="h-screen bg-mygray lg:w-[258%] lg:block hidden relative">
          {/* logo */}
          <div className="absolute w-96">
            <img src={Frame} alt="" />
          </div>

          {/*  */}
          <div className="">
            <div className="h-screen">
              <img
                style={{}}
                src={Doctorimg}
                alt=""
                srcSet=""
                className="h-screen"
              />
            </div>
          </div>
        </div>

        {/* right screen */}
        <div className='w-full sm:w-full lg:1/5  h-full sm:text-center  lg:text-start px-12 md:px-8 lg:px-8'>
          {/* logo */}
          <div className='flex justify-center lg:hidden'>
          <div className='w-24 '>
          <img src={Logo} alt=""  />
         </div>
         </div>

          <div className=' pt- sm:pt- md:pt-6 lg:pt-14  '>
            <h1 className='mb-one font-bold'>
              <span className='text-[18px] text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700'>
                Welcome back to DociHealthcare!
              </span>
              👋🏻
            </h1>
          </div>

          <h2 className='text-nextgray text-[14px] font-medium '>Please sign-up to acess your account and get started</h2>

          {/* form */}
          <Registerform />
        </div>
      </div>
    </div>
  );
};

export default Signup;
