import { useContext } from "react";
import { login, logout } from "../services/auth/auth";
import useNotification from "./useNotification";
import { UtilityContext } from "../contexts/Utility/UtilityContext";


const useAuth = () => {
  const { handleError, handleSuccess } = useNotification();
  const { setShowLoader } = useContext(UtilityContext);

  const loginUser = (email, password) => {
    return new Promise((resolve) => {
      setShowLoader(true);
      login(email, password)
        .then((res) => {
          resolve(res);
          handleSuccess("Login Success");
        })
        .finally(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          handleError(error);
          setShowLoader(false);
        });
    });
  };

  const logoutUser = () => {
    return new Promise((resolve) => {
      setShowLoader(true);
      console.log("deleteSession");
      logout()
        .then((res) => {
          console.log("deleteSession");
          resolve(res);
        })
        .finally(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          handleError(error);
          setShowLoader(false);
        });
    });
  };

  return {
    loginUser,
    logoutUser,
  };
};

export default useAuth;
