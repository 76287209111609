import React, {useEffect} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useRef, useState } from "react";

import useScheduler from "../../hooks/useScheduler";
// import { useRouter } from "next/router";

export default function Calendar() {
  // const router = useRouter();
  const { allscheduler, getAllSchedulerList } = useScheduler();
  const [events, setEvents] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    getAllSchedulerList();
  }, []);
  

  useEffect(() => {
    const transformedEvents = allscheduler.map((scheduler) => ({
      title: `${scheduler?.doctors?.map(d=>`Dr. `+d.label)}`,
      start: `${scheduler.date}T${scheduler.starttime}`,
      end: `${scheduler.date}T${scheduler.endtime}`,
    }));
    setEvents(transformedEvents);
  }, [allscheduler]);

  const start = new Date();
  const end = new Date(new Date().setMinutes(start.getMinutes() + 30));

  const data = [
    {
      title: "sala 1",
      start,
      end,
      backgroundColor: "green",
      extendedProps: { id: 1 },
    },
    {
      title: "sala 2",
      start: new Date(new Date().setHours(start.getHours() + 1)),
      end: new Date(new Date().setHours(start.getHours() + 2)),
      backgroundColor: "purple",
      extendedProps: { id: 2 },
    },
    {
      title: "sala 3",
      start: new Date(new Date().setHours(start.getHours() + 2)),
      end: new Date(new Date().setHours(start.getHours() + 3)),
      backgroundColor: "#000",
      extendedProps: { id: 3 },
    },
  ];

  // const [events, setEvents] = useState(allscheduler);

  const calendarRef = useRef(null);

  return (<>

    {/* Put this part before </body> tag */}
    <input type="checkbox" checked={Object.keys(details).length > 0 ? true : false} id="my-modal-4" className="modal-toggle" />
    <label htmlFor="my-modal-4" className="modal cursor-pointer" onClick={() => {
      setDetails({})
    }} style={{zIndex:100000000000}}>
      <label className="modal-box relative" htmlFor="" >
        <label htmlFor="my-modal-4" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={() => {
          setDetails({})
        }}>✕</label>
        <h3 className="text-lg font-bold pb-4">Doctors({details?.startDate} - {details?.endDate})</h3>
        {Object.keys(details).length > 0 && details.doctors.map(det => (
          <p className="py-2">- {det}</p>
        ))}
        
      </label>
    </label>
    
    <div style={{ padding: 20 }}>
      <FullCalendar
        nowIndicator={true}
        eventClick={(info) => {
          console.log(info.event, info.event.title)
          // split the string with comma
          const popDetails = {
            doctors: info.event.title.split(','),
            startDate: new Date(info.event.startStr).toLocaleTimeString(),
            endDate: new Date(info.event.endStr).toLocaleTimeString(),

          }
          setDetails(popDetails)
        }}
      
        editable={true}
        views={{
          dayGrid: {
            selectable: true,
          },
          timeGrid: {
            selectable: true,
            // dayMaxEventRows: 6,
          },
          dayGridMonth: {
            selectable: false,
          },
        }}
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        // initialView="timeGridWeek"
        eventDrop={(info) => {
          const eventFiltered = events.filter(
            (event) => event.extendedProps.id !== info.event.extendedProps.id
          ) ;
          setEvents([
            ...eventFiltered,
            {
              title: info.event.title,
              start: info.event.startStr,
              end: info.event.endStr,
              backgroundColor: info.event.backgroundColor,
              extendedProps: { id: info.event.extendedProps.id },
            },
          ]);
          alert("Dropped " + info.event.title);
        }}
        eventResize={(info) => {
          const eventFiltered = events.filter(
            (event) => event.extendedProps.id !== info.event.extendedProps.id
          ) ;
          setEvents([
            ...eventFiltered,
            {
              title: info.event.title,
              start: info.event.startStr,
              end: info.event.endStr,
              backgroundColor: info.event.backgroundColor,
              extendedProps: { id: info.event.extendedProps.id },
            },
          ]);
          // alert("Resized " + info.event.title);
        }}
        select={(info) => {
          // @ts-ignore
          // setEvents((event) => {
          //   const newId = events[events.length - 1].extendedProps.id + 1;
          //   return [
          //     ...event,
          //     {
          //       title: `sala ${newId}`,
          //       start: info.startStr,
          //       end: info.endStr,
          //       backgroundColor: "gray",
          //       extendedProps: { id: newId },
          //     },
          //   ];
          // });
          // alert("selected " + info.startStr + " to " + info.endStr);
        }}
        events={events}
        
        dayMaxEventRows={true}
        // eventLimit={1}
        // eventLimitText={'more'}
        timeZone={"UTF"}
        titleFormat={{ year: "numeric", month: "long" }}
        // allDayText={"24h"}
        allDaySlot={false}
        buttonText={{
          today: "today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        }}
        customButtons={{
          custom1: {
            text: "custom 1",
            hint: "Next 2022",
            // click: () => {
            //   calendar.changeView( 'resourceTimelineWeek' );
            //   calendar.incrementDate( { days: -7 } );
            //   console.log(
            //     calendarRef.current.calendar.currentData.dateProfile
            //       .currentRange
            //   );
            // },
          },
      
        }}
        headerToolbar={{
          left: "dayGridMonth,timeGridWeek,timeGridDay custom1",
          center: "title",
          right: "custom2 today prevYear,prev,next,nextYear",
        }}
      />
    </div>
  </>
  );
}
