import React, { useContext, useState, useEffect } from "react";
import Layout from "../components/LoggedIn/Layout";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../components/photos/default_user.png";
import useNotification from "../hooks/useNotification";
import FormInput from "../components/FormComponent/Input";
import { DataContext } from "../contexts/Data/DataContext";
import useDoctor from "../hooks/useDoctor";
import { useUser } from "../hooks/useUser";
import ReactImageFallback from "react-image-fallback";

import { UserContext } from "../contexts/User/UserContext";
import { showContext } from "../contexts/Form/ShowContext";

import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const DoctorsCollection = "63627685685cfb4029e9";
const account = new Account(client);

const DB = new Databases(client);

// get patient by id
async function getDoctorsbyId(id) {
  console.log(`userID`, id);
  const data = await DB.listDocuments(DBID, DoctorsCollection, [
    Query.equal("userID", id),
  ]);
  return data?.documents[0];
}

const Settings = () => {
  const {
    allDoctors,
    setAllDoctors,
    doctors,
    setDoctors,
    updateDoctor,
    getAllDoctorsList,
  } = useDoctor();
  const { doctorsInfo, setDoctorsInfo, doctor, setDoctor } =
    useContext(DataContext);
  const navigate = useNavigate();
  const { handleError, handleSuccess } = useNotification();
  const [dataSaved, setDataSaved] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { doctorDetails, userDetails, setUserDetails, setDoctorDetails } =
    useContext(UserContext);
  const { isShown, setIsShown } = useContext(showContext);
  

  const [updatedSettings, setUpdatedSettings] = useState({
    
  });
  useEffect(() => {
    // if (!doctors)
    console.log("doctor id is => ", doctors?.documents[0].$id);
    getDoctorsbyId(doctors?.documents[0].$id).then((data) => {
      setAllDoctors(data);
    });
  }, [dataSaved, doctors]);

  useEffect(() => {
    console.log("doctor details is => ", doctorDetails);
  }, [doctorDetails]);


  const handleSubmit = async (e) => {
    console.log({ event: e });
    e.preventDefault();

    if (doctorDetails?.userID) {
      // Update data
      updateData(updatedSettings);
    }
  };

  const updateData = async (data) => {
    try {
      console.log(`doctor => `, data);
      const doctorId = doctorDetails.$id
      console.log('update doctor that has id => ', doctorId, " with data => ", data)
      setLoading(true)
      const response = await DB.updateDocument(DBID, DoctorsCollection, doctorId,{...data});
      console.log('Data updated:', response);
      if (response) {
        handleSuccess("Updated sucessfully");
      }
      else {
        handleError("an error occured");
        
      }
      // setUpdated(true)

    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      // setLoading(false);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUpdatedSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let userImage =
    doctorDetails?.image ||
    `https://ui-avatars.com/api/?name=${
      doctorDetails?.first_name + " " + doctorDetails?.last_name
    }&background=B21E14&color=fff`;

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="md:py-12">
          <div className="text-center text-gray-500 text-5xl">Edit Profile</div>
          <div className=" h-full bg-base-100 shadow-xl md:grid grid-cols-3">
            <figure className="   px-10 md:pt-10  flex justify-center  md:flex justify-start">
              <div className="avatar">
                <div className="mask mask-squircle w-full h-1/2 shadow-2xl">
                  <ReactImageFallback
                    onClick={() => setIsShown(!isShown)}
                    src={userImage}
                    fallbackImage={defaultImage}
                    initialImage={defaultImage}
                    alt=""
                  />
                </div>
              </div>
            </figure>

            <div className="md:pt-12 md:pr-4  px-24 md:px-0 text-center md:text-start">
              <div className="">
                <label className="block text-sm  font-medium  text-consultblack ">
                  First Name
                </label>
                <input
                  name="first_name"
                  type="text"
                  defaultValue={doctorDetails?.first_name}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="lastname"
                  className="block text-sm  font-medium  text-consultblack"
                >
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  defaultValue={doctorDetails?.email}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>

              {/* <div className="card-actions">
              <button className="btn btn-primary">Update</button>
            </div> */}
            </div>

            <div className=" md:pt-12 md:pr-4  px-24 md:px-0 text-center md:text-start">
              <div>
                <label className="block text-sm  font-medium  text-consultblack">
                  Last Name
                </label>
                <input
                  name="last_name"
                  type="text"
                  defaultValue={doctorDetails?.last_name}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>

              <div>
                <label className="block text-sm  font-medium  text-consultblack">
                  Designation
                </label>
                <input
                  name="designation"
                  type="text"
                  defaultValue={doctorDetails?.designation}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>

              <div className="flex justify-end pr-12 pb-32 pt-12">
                <button
                  type="submit"
                  // onClick={() => console.log("clicked")}
                  className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
                >
                  Update
                </button>
              </div>
            </div>
            {/* <div></div> */}
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Settings;
