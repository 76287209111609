import React from "react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import FormInput from "./Input";
import Union from "../photos/Union.png";

const Cancel = (props) => {
  return (
    <div className={"w-3 items-center mt-4 ml-2 cursor-pointer"} onClick={props.onClick}>
      <img src={Union} alt='' />
    </div>
  );
};

const InputList = ({ values, onChange, ...props }) => {
  values = values || [];
  return (
    <div {...props}>
      {values.map((value,index) => (
        <div className='grid grid-cols-8'>
          <span className='col-span-7'>
            <FormInput
              onChange={(e) => {
                onChange(values.map((v) => (v === value ? e.target.value : v)));
              }}
              name={""}
              type='text'
              value={value}
              placeholder=''
              required='yes'
              size='w-full h-8'
            />
          </span>
          <div className='flex justify-center'>
            <Cancel
              onClick={() => {
                console.log("clicked", values, value);
                const filtered = values.filter((_,i) => i !== index)
                console.log({filtered})
                onChange(filtered);
              }}
            />
          </div>
        </div>
      ))}

      <button
        onClick={() => {
          onChange([...values, ""]);
        }}
        className='inline-flex justify-center items-center text-center w-1/2  lg:w-3/2 text-sm gap-2 h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg:white rounded-md'
      >
        <BiPlus className='text-red-500 items-center mt-1  ' />
        <div className='text-[12px] md:text-[18px]'>Add new condition</div>
      </button>
    </div>
  );
};

export default InputList;
