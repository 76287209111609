import {
  createConsultation,
  getAllConsultation,
  } from "../../utils/appwrite";
  
  export const createNewConsultation = (
    userID,
    complaints,
    diagnosis,
    prescribedrug,
    rtest,
    date,
    sensoryOne,
    sensoryTwo,
    sensoryNote,
    integumentaryOne,
    integumentaryTwo,
    integumentaryNote,
    circulatoryOne,
    circulatoryTwo,
    circulatoryNote,
    respiratoryOne,
    respiratoryTwo,
    respiratoryNote,
    dentalOne,
    dentalTwo,
    dentalNote,
    psychosocialOne,
    psychosocialTwo,
    psychosocialNote,
    nutritionOne,
    nutritionTwo,
    nutritionNote,
    gitOne,
    gitTwo,
    gitNote,
    
  ) => {
    return createConsultation(
      userID,
      complaints,
      diagnosis,
      prescribedrug,
      rtest,
      date,
      sensoryOne,
      sensoryTwo,
      sensoryNote,
      integumentaryOne,
      integumentaryTwo,
      integumentaryNote,
      circulatoryOne,
      circulatoryTwo,
      circulatoryNote,
      respiratoryOne,
      respiratoryTwo,
      respiratoryNote,
      dentalOne,
      dentalTwo,
      dentalNote,
      psychosocialOne,
      psychosocialTwo,
      psychosocialNote,
      nutritionOne,
      nutritionTwo,
      nutritionNote,
      gitOne,
      gitTwo,
      gitNote,
    );
  };
  
  export const getConsultation = () => {
    return getAllConsultation().then((res) => {
      return res;
    });
  };