import { createContext, useState } from "react";
export const ScheduleCalendarContext = createContext({});

const Provider = ScheduleCalendarContext.Provider;
export const ScheduleCalendarProvider = ({ children }) => {
    const [workShift, setWorkShift] = useState("");
  
  
  
  
  
  const data = {
    workShift, setWorkShift
  };

  return <Provider value={data}>{children}</Provider>;
};
