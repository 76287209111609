import { Route, Routes } from "react-router-dom";
import Patientinformation from "../../pages/Patientinformation.jsx";
import Medicalhistory from "../../pages/Medicalhistory.jsx";
import Consultation from "../../pages/Consultation.jsx";
import Referral from "../../pages/Referral.jsx";
import Appointment from "../../pages/Appointment.jsx";
// import Video from "../../pages/Video.jsx";
import Patient from "../../pages/Patient";
import Scheduler from "../../pages/Scheduler";
import ScheduleCalendar from "../../pages/ScheduleCalendar";
import Chat from "../../pages/Chat";
import Settings from "../../pages/Settings";
import useUser from "../../hooks/useUser.jsx";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/User/UserContext.jsx";
import VideoCall from "../../pages/VideoCall.jsx";

const LoggedInContainer = () => {
  const { getuserDetails, setShowLoader, logOutUser } = useUser();
  const { doctorDetails } = useContext(UserContext);

  useEffect(() => {
    getuserDetails();
  }, []);

  if (!doctorDetails) logOutUser();
  if (!doctorDetails) return <h1>Opps!, sorry not a doctor</h1>;

  return (   
    <Routes>
      <Route path='/' element={<Appointment />} />
      <Route path='/overview' element={<Appointment />} />
      <Route path='/patient-information' element={<Patientinformation />} />
      <Route path='/medical-history' element={<Medicalhistory />} />
      <Route path='/consultation' element={<Consultation />} />
      <Route path='/referral' element={<Referral />} />
      <Route path='/patient' element={<Patient />} />
      <Route path='/scheduler' element={<Scheduler />} />
      <Route path='/schedulecalendar' element={<ScheduleCalendar />} />
      <Route path='/chat' element={<Chat />} />
      {/* <Route path='/video/:channel' element={<Video />} /> */}
      <Route path='/settings' element={<Settings />} />
      <Route path="/videocall/:id" element={<VideoCall />}   />
      {/* <Route path="/videocall/:id/:abc" element={<VideoCall />}   /> */}


      {/* <Route path='/sign-up-patient/:id/new' element={<Video />} /> */}
    </Routes>
  );
};

export default LoggedInContainer;
