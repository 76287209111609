import React, { useContext, useState } from "react";
import Layout from "../components/LoggedIn/Layout";
import { FirstPopup } from "../components/FormComponent/Firstpopup";
import { Schedulersvg } from "../assets/SVG";
import {Link, useNavigate} from "react-router-dom"

//





const Scheduler = () => {
  
  const [firstpopup, setFirstPopup] = useState(false);  
  const navigate = useNavigate();

  return (
    <>
      <Layout className="">
        <div className=" grid-none cols-none md:grid grid-cols-2 mt-10 relative">
          <div className="pr-4 md:pr-0 items-center justify-center pt-18 md:pt-48 pl-8 absolute md:relative top-24 md:top-0">
            <div className="text-nextgray text-5xl font-bold mb-2">
              <div className="flex">
                <div>Schedule Calendar </div>
              </div>
            </div>
            <div>
              <div className="text-grayon">
               Know when you are on duty. Do click the button below.
              </div>
            </div>

            {/* popup */}
            {/* <FirstPopup firstpopup={firstpopup}  setFirstPopup={setFirstPopup} /> */}
           {/* <OthersPopup/>  */}
           {/* <ShiftPopup/> */}
           
            <div>
              <button className=" mt-4 w-1/2  lg:w-3/2 text-sm gap-2 h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg:white rounded-md">
                <div className="text-red-500 items-center justify-center">
                 
                <Link to='/ScheduleCalendar'>Turn on scheduler</Link>
                </div>
              </button>
            </div>
          </div>

          {/* right screen */}
          <>
            <div className="hidden  md:flex justify-center w-full px-12 opacity-20 md:opacity-100">
              <Schedulersvg styles="" />
            </div>
          </>
        </div>
      </Layout>
    </>
  );
};

export default Scheduler;
