import { createMessage, getChat, getMessages } from "../../utils/appwrite";

export const getAllChat = (sender_id) => {
  return getChat(sender_id).then((res) => {
    return res;
  });
};

export const sendChatMessage = (message, sender_id, to_id, chatID, message_sent) => {
  return createMessage(message, sender_id, to_id, chatID, message_sent).then((res) => {
    return res;
  });
};

export const getChatMessages = (chat_id) => {
  return getMessages(chat_id).then((res) => {
    return res;
  });
};
