import { useState, createContext, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const [doctorDetails, setDoctorDetails] = useState({});

  useEffect(() => {
    if (localStorage.getItem("doci_doctor_user")) {
      const persisted = JSON.parse(localStorage.getItem("doci_doctor_user"));
      setUserDetails(persisted);
    }
  }, []);

  const userContextValue = {
    userDetails,
    setUserDetails,
    doctorDetails,
    setDoctorDetails,
  };

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};
