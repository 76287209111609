import { createContext, useState } from "react";
export const AppointmentContext = createContext();

const Provider = AppointmentContext.Provider;
export const AppointmentProvider = ({ children }) => {
   
  
  const data = {
  
  };

  return <Provider value={data}>{children}</Provider>;
};
