import React, { useState, useEffect} from "react";
import { useContext } from "react";
import Layout from "../components/LoggedIn/Layout.jsx";
import FormInput from "../components/FormComponent/Input.jsx";
import Largetextarea from "../components/FormComponent/Largetextarea.jsx";
import { DataContext } from "../contexts/Data/DataContext";
import { Link, useNavigate } from "react-router-dom";
import useNotification from "../hooks/useNotification";
import usePatient from "../hooks/usePatient.jsx";
import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client.setEndpoint(process.env.REACT_APP_API).setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
// const referralCollection = process.env.REACT_APP_REFERRALS;
const referralCollection = "640e64c0553d673e62b9"
const account = new Account(client);

const DB = new Databases(client);


// get patient by id
async function getReferralByPatientId(id) {
  console.log(`patientID`,id)
  const data = await DB.listDocuments(DBID, referralCollection, [Query.equal("patientID", id)]);
  return data?.documents[0]
}

const Referral = () => {
  const { referral, setReferral } = useContext(DataContext);
  const { handleError, handleSuccess } = useNotification();
  const [updated, setUpdated] =useState(false)
  const navigate = useNavigate();
  const [dataSaved, setDataSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const {patient} = usePatient()
 
  // const { register, handleSubmit, reset, formState } = useForm();

  
  useEffect(() => {
    if(!patient) navigate('/patient-information')
    getReferralByPatientId(patient?.documents[0].$id).then(data => {
      setReferral(data)
    })
  },[dataSaved, patient, navigate])


  const handleSubmit = async (e) => {
    console.log({event:e})
    e.preventDefault()
    console.log('data is => ',referral)
      // event.preventDefault();
    // console.log({ patient })
    
    if (referral?.patientID) {
      // Update data
      updateData(referral);
    } else {
      // Save data
      saveData(referral);
    }
  }
  
  const saveData = async (data) => {
    console.log({data});
    try {
  
      const result2 = await DB.createDocument( DBID,
        referralCollection,
        ID.unique(), {
                            ...data,
                            patientID:patient?.documents[0].$id,
        }
      );
      console.log("Data saved:", result2);
      setDataSaved(true);

      if (!result2) throw new Error("failed");

      console.log("sucessful");

      // await createOrganizationUser({ userID: result1.$id, OrgUserID: result2.$id })
      // handleSuccess("Organization created successfully");
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Saved Sucessfully")
    }
  };

  
  const updateData = async (data) => {
  
    try {
      console.log(`updating... patient ${patient?.documents[0].$id}`)
      const response = await DB.updateDocument(DBID, referralCollection, referral.$id,
      {...data,
        $collectionId: undefined,
        $createdAt: undefined,
        $databaseId: undefined,
        $id: undefined,
        $permissions: undefined,
        $updatdAt: undefined,
      });
      console.log('Data updated:', response);
      setUpdated(true)
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Updated sucessfully");
    }
  }

  


    


  const changeHandler = (e) => {
    setReferral({
      ...referral,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Layout showHeading>
      {/* info */}
      <form onSubmit={handleSubmit}>
      <div className="px-8 lg:px-0 pb-6">
        <div>
          <h1 className="block text-sm  font-medium  text-consultblack">
            Doci Healthcare Referral
          </h1>
          <h2 className="text-grayon text-sm ">
            The report below was submitted by Doci Virtual Care Physicians
          </h2>
        </div>

        {/* input vcp fitrstname */}
        <div className=" grid-none cols-none md:grid grid-cols-2 mt-12 ">
          <div className="pr-0 md:pr-8">
            <div className="text-[14px] font-bold text-consultblack">
              VCP First Name
            </div>
            <div>
              <span>
                <FormInput
                  name={"vcpfirstname"}
                  type="text"
                  value={referral?.vcpfirstname}
                  register={() => { }}
                  placeholder="Amos"
                  required="yes"
                  onChange={changeHandler}
                  size="w-full  h-8"
                />
              </span>
            </div>
          </div>

          {/* input vcp last name */}
          <div className="pr-0 md:pr-8">
            <div className="text-[14px] font-bold text-consultblack">
              VCP Last Name
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  register={() => { }}
                  name={"vcplastname"}
                  type="text"
                  value={referral?.vcplastname}
                  placeholder="Becca"
                  required="yes"
                  size="w-full  h-8"
                />
              </span>
            </div>
          </div>

          {/* input medical specialist */}
          <div className="pr-0 md:pr-8">
            <div className="text-[14px] font-bold text-consultblack">
              Physician Medical Specialties
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  name={"medicalspecialties"}
                  register={() => { }}
                  type="text"
                  value={referral?.medicalspecialties}
                  placeholder="ENT Doctor"
                  required="yes"
                  size="w-full  h-8"
                />
              </span>
            </div>
          </div>

          {/* input VCP Email */}
          <div className="pr-0 md:pr-8">
            <div className="text-[14px] font-bold text-consultblack">
              VCP Email
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  name={"vcpemail"}
                  register={() => { }}
                  type="email"
                  value={referral?.vcpemail}
                  placeholder="someone@gmail.com"
                  required="yes"
                  size="w-full  h-8"
                />
              </span>
            </div>
          </div>

          {/* input vcp phone-number */}
          <div className="pr-0 md:pr-8">
            <div className="text-[14px] font-bold text-consultblack ">
              VCP Phone Number
            </div>
            <div>
              <span>
                <FormInput
                  name={"vcpphonenumber"}
                  register={() => { }}
                  type="text"
                  value={referral?.vcpphonenumber}
                  placeholder="Phone number"
                  required="yes"
                  onChange={changeHandler}
                  size=""
                />
              </span>
            </div>
          </div>
        </div>

        {/* input consultation details*/}
        <div className="text-grayon text-[16px] pt-8 pb-3">My consultation</div>
        <div className="text-consultblack text-[14px] font-medium">
          Details about the patient's condition
        </div>
        <Largetextarea
          onChange={changeHandler}
          name={"conditiondetails"}
          register={() => { }}
          type="text"
            value={referral?.conditiondetails}
            defaultValue={referral?.conditiondetails}
          placeholder="Type here..."
          rols={5}
            cols={150}
            
          // className="w-full gap-2 focus-none  border-grayon rounded-md mb-2 border drop-shadow-sm   placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2"
        />

        {/* input reason for physicain change  */}
        <div className="text-consultblack text-[14px] font-medium">
          Why does the patient need to be seen by another physician
        </div>

        <div>
          <div>
            <Largetextarea
              onChange={changeHandler}
              name={"newphysicianreason"}
                value={referral?.newphysicianreason}
                defaultValue={referral?.newphysicianreason}
              register={() => { }}
              type="text"
              rols={5}
              cols={150}
              placeholder="Type here..."
              // className=" w-96 md:w-full gap-2 focus-none  border-grayon rounded-md mb-2 border drop-shadow-sm   placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2"
            />
          </div>
        </div>

        {/* left screen */}
        <div className=" cols-none grid-none md:grid grid-cols-2 mt-12 ">
          {/* input referred physician  */}
          <div className="pr-0 lg:pr-8">
            <div className="text-[14px] font-bold text-consultblack">
              Name of Referred Physician
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  name={"referredphyscianfname"}
                  register={() => { }}
                  type="text"
                  value={referral?.referredphyscianfname}
                  placeholder="First Name"
                  required="yes"
                  size="w-full h-8"
                />
              </span>
            </div>

            {/* input physician specialities  */}
            <div className="text-[14px] font-bold text-consultblack">
              Physician Medical Specialties
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  name={"newphysicianmedicalspecialties"}
                  register={() => { }}
                  type="text"
                  value={referral?.newphysicianmedicalspecialties}
                  placeholder="ENT Doctor"
                  required="yes"
                  size="w-full h-8"
                />
              </span>
            </div>

            {/* input ID number */}
            <div className="text-[14px] font-bold text-consultblack">
              ID Number of Referred Physician
            </div>
            <div>
              <span>
                <FormInput
                  onChange={changeHandler}
                  name={"newphysicianid"}
                  register={() => { }}
                  type="ID"
                  value={referral?.newphysicianid}
                  placeholder="000rt-234Doc"
                  required="yes"
                  size="w-full  h-8"
                />
              </span>
            </div>
          </div>

          {/* right screen */}
          <div className="">
            {/* referred physician name */}
            <div>
              <div className="text-[14px] font-bold text-consultblack">
                Name of Referred Physician
              </div>
              <div>
                <span>
                  <FormInput
                    onChange={changeHandler}
                    name={"referredphyscianlname"}
                    register={() => { }}
                    type="text"
                    value={referral?.referredphyscianlname}
                    placeholder="Last Name"
                    required="yes"
                    size="w-full  h-8"
                  />
                </span>
              </div>
            </div>

            {/* Physicain Email */}
            <div className="pr-0 lg:pr-">
              <div className="text-[14px] font-bold text-consultblack">
                Email of Referred Physician
              </div>
              <div>
                <span>
                  <FormInput
                    onChange={changeHandler}
                    name={"newphysicianemail"}
                    register={() => { }}
                    type="text"
                    value={referral?.newphysicianemail}
                    placeholder="someone@gmail.com"
                    required="yes"
                    size="w-full  h-8"
                  />
                </span>
              </div>
            </div>

            {/* Referred Physicain number */}
            <div className="pr-0 lg:pr-">
              <div className="text-[14px] font-bold text-consultblack">
                Phone Number of Referred Physician
              </div>
              <div>
                <span>
                  <FormInput
                    name={"newphysiciannumber"}
                    register={() => { }}
                    type="text"
                    value={referral?.newphysiciannumber}
                    placeholder="Phone number"
                    required="yes"
                    onChange={changeHandler}
                    size=""
                  />
                </span>
              </div>
              </div>
              
              <div className="flex justify-end pr-12 pb-32 pt-48">
            <button
              type="submit"
              // onClick={() => console.log("clicked")}
              className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
            >
              Save
            </button>
          </div>
          </div>
        </div>
        </div>
      </form>
    </Layout>
  );
};

export default Referral;
