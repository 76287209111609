import React, { useState, useEffect } from "react";
import Layout from "../components/LoggedIn/Layout.jsx";
import FormInput from "../components/FormComponent/Input.jsx";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "../components/FormComponent/InputArea.jsx";
import { useContext } from "react";
import InputList from "../components/FormComponent/AddCondition.jsx";
import { DataContext } from "../contexts/Data/DataContext";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import useNotification from "../hooks/useNotification";
import usePatient from "../hooks/usePatient.jsx";


import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client.setEndpoint(process.env.REACT_APP_API).setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const medicalCollection = process.env.REACT_APP_PATIENTS_MEDICAL_INFOS;
const account = new Account(client);

const DB = new Databases(client);

// get patient by id
async function getMedicalHistoryByPatientId(id) {
  console.log(`patientID`,id)
  const data = await DB.listDocuments(DBID, medicalCollection, [Query.equal("patientID", id)]);
  return data?.documents[0]
}

const Medicalhistory = () => {
  const navigate = useNavigate();
  const {medicalhistory, setMedicalHistory} = useContext(DataContext);
  const { handleError, handleSuccess } = useNotification();
  const [dataSaved, setDataSaved] = useState(false);
  const [updated, setUpdated] = useState(false); 
  const [loading, setLoading] = useState(false);
  const {patient} = usePatient()
  

  useEffect(() => {
    if(!patient) navigate('/patient-information')
    getMedicalHistoryByPatientId(patient?.documents[0].$id).then(data => {
      setMedicalHistory(data)
    })
  },[dataSaved, patient])


     
  const handleSubmit = async (e) => {
    console.log({event:e})
    e.preventDefault()
    console.log('data is => ',medicalhistory)
      // event.preventDefault();
    // console.log({ patient })
    
    if (medicalhistory?.patientID) {
      // Update data
      updateData(medicalhistory);
    } else {
      // Save data
      saveData(medicalhistory);
    }
  }

  const saveData = async (data) => {
    console.log({data});
    try {
  
      const result2 = await DB.createDocument( DBID,
        medicalCollection,
        ID.unique(), {
                            ...data,
                            patientID:patient?.documents[0].$id,
        }
      );
      console.log("Data saved:", result2);
      setDataSaved(true);

      if (!result2) throw new Error("failed");

      console.log("sucessful");

      // await createOrganizationUser({ userID: result1.$id, OrgUserID: result2.$id })
      // handleSuccess("Organization created successfully");
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Saved Sucessfully")
    }
  };

 
  

  
  const updateData = async (data) => {
  
    try {
      console.log(`updating... patient ${patient?.documents[0].$id}`)
      const response = await DB.updateDocument(DBID, medicalCollection, medicalhistory.$id,
      {...data,
        $collectionId: undefined,
        $createdAt: undefined,
        $databaseId: undefined,
        $id: undefined,
        $permissions: undefined,
        $updatdAt: undefined,
      });
      console.log('Data updated:', response);
      setUpdated(true)
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Updated sucessfully");
    }
  }

  

  const changeHandler = (e) => {
    setMedicalHistory({
      ...medicalhistory,
      [e.target.name]: e.target.value,
    });
  };
  

  


  return (
    <Layout showHeading>
      <form onSubmit={handleSubmit}>
      <div className=' px-8 md:px-0 md:pr-8  grid-none cols-none md:grid grid-cols-2 '>
        <div>
          <div className='text-searchicon text-sm'>Ensure you confirm medical history with Patient</div>
          <div>
            {/* medical condition */}
            <div className=' pb-12 mt-2'>
              <label htmlFor='medical-condition' className='block text-sm  font-medium  text-consultblack'>
                Previous chronic medical condition
              </label>
                <div >
                  <InputList
                    name={"medicalcondition"}
                    // values={medicalcondition}
                    values={medicalhistory?.medicalcondition}
                    defaultValue={medicalhistory?.medicalcondition}
                    register={() => { }}
                    onChange={(values) => {
                      setMedicalHistory({
                        ...medicalhistory,
                        medicalcondition: values,
                      });
                    }}
                    className='w-3/2'
                  />
                </div>
            </div>
          </div>

          {/*  family conditions*/}
          <label htmlFor='medical-condition' className='block text-sm  font-medium  text-consultblack mb-6'>
            Any medical condition in your family?
          </label>
          <div>
            <InputList
              name={"familyconditions"}
              // values={medicalhistory?.familyconditions}
              // defaultValue={medicalhistory?.familyconditions}
              // eslint-disable-next-line no-undef
              values={medicalhistory?.familyconditions}
                onChange={(values) => {
                  console.log(values)
                setMedicalHistory({
                  ...medicalhistory,
                  familyconditions: values,
                  
                });
              }}
              className='w-3/2'
            />
          </div>

          {/* death condition  */}

          <label htmlFor='deathcondition' className='block text-sm  font-medium  text-consultblack mt-12 mb-1'>
            Has any family member died from this condition?
          </label>

          <div className='w-full'>
            <div className='custom-select w-full ' style={{}}>
              <select
                name={"deathcondition"}
                // value={deathcondition}
                values={medicalhistory?.deathconditions}
                defaultValue={medicalhistory?.deathconditions}
                onChange={changeHandler}
                className=' w-full  h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
              >
                <option className='' value={"Yes"}>
                  Yes
                </option>
                <option value={"No"}>No</option>
              </select>
            </div>
          </div>

          {/* condition */}
          <label htmlFor='condition' className='block text-sm  font-medium  text-consultblack'>
            What is the condition?
          </label>

          <FormInput
            handleChange={changeHandler}
              name={"condition"}
              value={medicalhistory?.condition}
              defaultValue={medicalhistory?.condition}
            type='text'
            // value={condition}
            placeholder='Hypertension'
            register={()=>{}}
            required='yes'
            size=''
          />

          {/* Any medication */}
          <label htmlFor='anymedication' className='block text-sm  font-medium  text-consultblack'>
            Are you on any medication
          </label>

          <div className='w-full'>
            <div className='custom-select w-full ' style={{}}>
              <select
                name={"anymedication"}
                //anymedication}
                value={medicalhistory?.anymedication}
                 defaultValue={medicalhistory?.anymedication}
                onChange={changeHandler}
                className=' w-full h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
              >
                <option className='' value={"Yes"}>
                  Yes
                </option>
                <option value={"No"}>No</option>
              </select>
            </div>
          </div>

          {/* Medication name */}

          <label htmlFor='medicationname' className='block text-sm  font-medium  text-consultblack'>
            Name of medication
          </label>
          <div>
            <TextArea
              handleChange={changeHandler}
              name={"medicationname"}
              type='text'
              // value={medicationname}
              value={medicalhistory?.medicationname}
              defaultValue={medicalhistory?.medicationname}
              register={()=>{}}
              placeholder='Pyrazinamide (PZA), and
                  Ethambutol (EMB)'
              required='yes'
              size='w-full rows={10} cols={10} placeholder:text-center'
            />
          </div>

          {/* allergies  */}
          <label htmlFor='allergies' className='block text-sm  font-medium  text-consultblack'>
            Allergies
          </label>

          <FormInput
            handleChange={changeHandler}
            name={"allergies"}
            type='text'
            // value={allergies}
            value={medicalhistory?.allergies}
            defaultValue={medicalhistory?.allergies}
            register={()=>{}}
            placeholder='Food such as peanuts, milk and eggs'
            required='yes'
            size=''
          />
        </div>

        {/* right side */}
        <div className='pl-0 md:pl-8'>
          <div className='pr-6 md:pr-8 lg:pr-0 pb-12'>
            <div className='mt-4'>
              {/* Prior surgery */}
              <label htmlFor='Prior-surgery' className='block text-sm  font-medium  text-consultblack'>
                Prior surgery
              </label>

              <div className='w-full'>
                <div className='custom-select w-full ' style={{}}>
                  <select
                    name={"option"}
                    // value={option}
                    value={medicalhistory?.option}
                    defaultValue={medicalhistory?.option}
                    onChange={changeHandler}
                    className=' w-full h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
                  >
                    <option className='' value={"Yes"}>
                      Yes
                    </option>
                    <option value={"No"}>No</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Surgery */}
            <label htmlFor='surgery' className='block text-sm  font-medium  text-consultblack'>
              Specify surgery type
            </label>
            <div>
              <TextArea
                handleChange={changeHandler}
                name={"surgery"}
                value={medicalhistory?.surgery}
                defaultValue={medicalhistory?.surgery}
                type='text'
                register={()=>{}}
                // value={surgery}
                placeholder='Gallbladder removal (cholecystectomy)'
                required='yes'
                size='w-full  rows={10} cols={10} placeholder:text-center'
              />
            </div>

            {/* Surgery date */}
            <label htmlFor='surgerydate' className='block text-sm  font-medium  text-consultblack'>
              Date of surgery
            </label>

            <FormInput
              handleChange={changeHandler}
              name={"surgerydate"}
              register={()=>{}}
              type='surgerydate'
              // value={surgerydate}
              value={medicalhistory?.surgerydate}
              defaultValue={medicalhistory?.surgerydate}
              placeholder='28/09/1997'
              required='yes'
              size=''
            />

            {/* blood transfusion */}
            <div className='mt-4'>
              <label htmlFor='blood-transfusion' className='block text-sm  font-medium  text-consultblack mb-1'>
                Have you gotten blood transfusion?
              </label>

              <div className='w-full'>
                <div className='custom-select w-full ' style={{}}>
                  <select
                    name={"bloodtransfusion"}
                    value={medicalhistory?.bloodtransfusion}
            defaultValue={medicalhistory?.bloodtransfusion}
                    // value={bloodtransfusion}
                    onChange={changeHandler}
                    className=' w-full  h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
                  >
                    <option className='' value={"Yes"}>
                      Yes
                    </option>
                    <option value={"No"}>No</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Blood transfusion reason */}
            <label htmlFor='bloodtransfusionreason' className='block text-sm  font-medium  text-consultblack'>
              Reason for blood transfusion
            </label>
            <div>
              <TextArea
                handleChange={changeHandler}
                name={"bloodtransfusionreason"}
                register={()=>{}}
                type='text'
                // value={bloodtransfusionreason}
                value={medicalhistory?.bloodtransfusionreason}
                defaultValue={medicalhistory?.bloodtransfusionreason}
                placeholder='Because of the surgery ( I needed more blood)'
                required='yes'
                size='w-full  rows={10} cols={10} placeholder:text-start'
              />
            </div>

            {/*  alcohol consumption */}
            <label htmlFor='alcoholconsumption' className='block text-sm  font-medium  text-consultblack mb-1'>
              What is your achol consumption rate?
            </label>

            <div className='w-full'>
              <div className='custom-select w-full ' style={{}}>
                <select
                  name={"alcoholconsumption"}
                  onChange={changeHandler}
                  value={medicalhistory?.alcoholconsumption}
                    defaultValue={medicalhistory?.alcoholconsumption}
                  // value={alcoholconsumption}
                  className=' w-full  h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
                >
                  <option value={"high"} className=''>
                    High
                  </option>
                  <option value={"veryhigh"}>Very High</option>
                  <option value={"low"}>Low</option>
                  <option value={"Verylow"}>Very low</option>
                </select>
              </div>
            </div>

            {/* How regular */}
            <label htmlFor='howregularalcohol' className='block text-sm  font-medium  text-consultblack mb-1'>
              How regular?
            </label>

            <div className='w-full'>
              <div className='custom-select w-full ' style={{}}>
                <select
                  name={"howregularalcohol"}
                  
                  onChange={changeHandler}
                  // value={howregularalcohol}
                  value={medicalhistory?.howregularalcohol}
                  defaultValue={medicalhistory?.howregularalcohol}
                  className=' w-full  h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
                >
                  <option value={">500"} className=''>
                    More than 500ml daily
                  </option>
                  <option value={"<500"}>Less than 500ml daily</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex justify-end pr-12 pb-32 pt-48">
            <button
              type="submit"
              // onClick={() => console.log("clicked")}
              className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      </form>
    </Layout>
  );
};


export default Medicalhistory;
