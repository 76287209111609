import { createContext, useState } from "react";
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(null);
  const [allPatients, setAllPatients] = useState(null);
  const [patient, setPatient] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [allAppointments, setAllAppointments] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [showModalOne, setShowModalOne] = useState(false);
  const [clickedAppointment, setClickedAppointment] = useState(null)

  // Patient information Context
  const [patientinformation, setPatientInformation] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    phonenumber: "",
    age: "",
    bloodgroup: "",
    dateofbirth: "",
    weight: "",
    height: "",
  });



  const [doctorsInfo, setDoctorsInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
  });

  // Medical History Context
  const [medicalhistory, setMedicalHistory] = useState({
    medicalcondition: [],
    familyconditions: [],
    option: "",
    surgery: "",
    surgerydate: "",
    bloodtransfusionreason: "",
    bloodtransfusion: "",
    alcoholconsumption: "",
    howregularalcohol: "",
    deathcondition: "",
    condition: "",
    anymedication: "",
    medicationname: "",
    allergies: "",
  });

  // Consultation
  const [consultation, setConsultation] = useState({
    complaints: "",
    setComplaints: "",
    diagnosis: "",
    setDiagnosis: "",
    date: "",
    setDate: "",
    prescribedrug: "",
    setPrescribedrug: "",
    rtest: "",
    setRtest: "",
    ticked: [
      {
        sicknessTitle: "Sensory (Eyes, ears, nose, throat)",
        normal: true,
        notes: "head hurts badly",
      },
      {
        sicknessTitle: "Musculoskeletal (Mobility, swelling, stiff joint)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle: "Integumentary Rashes, irritation, pale, skin changes",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle:
          "Circulatory (chest pain, waking at night with breathlessness,leg swelling, cough)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle:
          "Respiratory (Shortness of breath, waking at night breathlessness, leg swelling, cough)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle: "Dental (Dentures)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle: "Psychosocial (Hallucinations, delusions)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle: "Nutrition (Diet, weight change, swallowing)",
        normal: false,
        notes: "vomiting daily",
      },

      {
        sicknessTitle: "GIT (Constipation, incontinence, diarrhoea)",
        normal: false,
        notes: "vomiting daily",
      },
    ],
  });

  const [consultations, setConsultations] = useState({
    complaints: "",

    diagnosis: "",

    prescribedrug: "",

    rtest: "",

    date: "",

    sensoryOne: "",
    

    sensoryNote: "",

    integumentaryOne: "",

    

    integumentaryNote: "",

    circulatoryOne: "",

  

    circulatoryNote: "",

    respiratoryOne: "",

   

    respiratoryNote: "",

    dentalOne: "",

    

    dentalNote: "",
    psychosocialOne: "",
   
    psychosocialNote: "",

    nutritionOne: "",

    

    nutritionNote: "",

    gitOne: "",


    gitNote: "",
  });

  //  Referral
  const [referral, setReferral] = useState({
    vcpfirstname: "",
    vcplastname: "",
    medicalspecialties: "",
    vcpemail: "",
    vcpphonenumber: "",
    conditiondetails: "",
    newphysicianreason: "",
    referredphyscianfname: "",
    referredphyscianlname: "",
    newphysicianmedicalspecialties: "",
    newphysiciannumber: "",
    newphysicianemail: "",
    newphysicianid: "",
  });

  // popup context
  const [selectedtime, setSelectedtime] = useState("Less than two hours");

  // otherspopup context

  const [othertime, setOtherTime] = useState([
    {
      otherpreferredtime: "Everyday",
      select: true,
    },
    {
      otherpreferredtime: "Two times in a week",
      select: false,
    },

    {
      otherpreferredtime: "Three times in a week",
      select: true,
    },

    {
      otherpreferredtime: "Four times in a week",
      select: false,
    },
  ]);

  const data = {
    doctorsInfo,
    setDoctorsInfo,
    doctor,
    setDoctor,
    allPatients,
    setAllPatients,
    allAppointments,
    setAllAppointments,
    notifications,
    setNotifications,
    patientinformation,
    setPatientInformation,
    medicalhistory,
    setMedicalHistory,
    consultation,
    setConsultation,
    consultations,
    setConsultations,
    referral,
    setReferral,
    selectedtime,
    setSelectedtime,
    othertime,
    setOtherTime,
    setPhoneNumber,
    phoneNumber,
    setPatient,
    patient,
    token,
    setToken,
    showModalOne,
    setShowModalOne,
    clickedAppointment,
    setClickedAppointment
  };

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};
