import React from "react";

const Largetextarea = ({
  name,
  placeholder,
  value,
  type,
  required,
  handleChange,
  onChange,
  rows,
  cols,
  size,
  register,
  ...props
}) => {
  return (
    <textarea
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      register={register}
      onChange={handleChange||onChange}
      required={required}
      {...(register? register(name): {})}
      area={required}
      rows={rows}
      cols={cols}
      {...props}
      className={
        (size?.length > 0 ? size : `w-full md:w-3/5 `) +
        ` gap-2 focus-none  border-slate-300 rounded-md mb-2 border drop-shadow-sm  placeholder:nextgray text-left pl-2 text-sm sm:text-md  bg-white focus:outline-none focus:border-consultbg focus:ring-consultbg mt-2`
      }
    />
  );
};

export default Largetextarea;
