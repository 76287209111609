import { Modal } from "@mantine/core";
import { useContext, useState } from "react";
import { DataContext } from "../../contexts/Data/DataContext";
import { UserContext } from "../../contexts/User/UserContext";
import { useGetAppointment } from "../../hooks/useAppointment";
import { LoadingOverlay } from "@mantine/core";
import { Spinner } from "../../assets/SVG";
import { acceptAppointment, rejectAppointment } from "../../utils/appwrite";
import { getAppointment } from "../../services/patients/patients";

export default function AppointmentModal() {
  const { showModalOne, setShowModalOne, clickedAppointment, setAllAppointments } = useContext(DataContext);
  const { userDetails} = useContext(UserContext);
  const [inProgress, setInProgress] = useState(false)

  const { data, loading } = useGetAppointment(clickedAppointment);
  console.log("clickedAppointment===>", clickedAppointment);
  console.log("data===>", data);

  const onClick = async (data) => {
    setInProgress(true)

    if (data?.$id && !data?.doctorID) {
      try {
        console.log("data?.$id", data?.$id)
        await acceptAppointment(userDetails?.$id, data?.$id);
      } catch (error) {
        console.log(error);
      }
    }
     else if (data?.doctorID) {
      try {
        await rejectAppointment(data?.$id);
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const res = await getAppointment();
      setAllAppointments(res?.documents);
    } catch (error) {
      console.error(error);
    }

    setInProgress(false)
    setShowModalOne(false)
  };

  return (
    <div>
      <Modal
        opened={showModalOne}
        onClose={() => {
          setShowModalOne(false);
        }}
        title="">
        {loading ? (
          <div className="m-auto centerFlex">
            {" "}
            <Spinner />{" "}
          </div>
        ) : null}
        {!loading  ? (
          <div className={`py-10 centerFlex `}>
            { inProgress ? (
              <Spinner />
            ) : (
              <button className={` border border-1 rounded-[6px] px-3 py-2 ${!data?.doctorID ? "border-green-400 text-green-400 " : "border-red-400 text-red-400 "}`} type="button" onClick={() => onClick(data)}>
                {!data?.doctorID ? "Accept appointment" : "Reject appointment"}
              </button>
            )}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
