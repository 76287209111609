import { useContext } from "react";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/User/UserContext";

const useNotification = () => {
  const navigate = useNavigate();
  const { setUserDetails } = useContext(UserContext);

  const logoutUser = (hideNotification) => {
    setUserDetails(null);
    localStorage.removeItem("doci_doctor_user");

    if (!hideNotification) {
      showNotification({
        title: "User logged out",
        message: "Log in to continue",
        color: "yellow",
      });
    }

    navigate("/login");
  };

  const handleSuccess = (message) => {
    showNotification({
      title: "Success",
      message,
      color: "green",
    });
  };

  const handleError = (error) => {
    if (!error.response) {
      return showNotification({
        title: "Network Error",
        message: "Please check your connection",
        color: "red",
      });
    }

    if (error?.response?.code === 401) {
      if (error?.response?.type === "user_invalid_credentials") {
        return showNotification({
          title: "Error",
          message: "Invalid email or password",
          color: "red",
        });
      }
      showNotification({
        title: "Session expired",
        message: "Please log in to continue",
        color: "yellow",
      });
      return logoutUser(true);
    }

    if (error?.response?.status === 500) {
      return showNotification({
        title: "Error",
        message: `${error?.response?.data?.message ?? "An error occured, please try again"} 🤥`,
        color: "red",
      });
    }

    if (typeof error?.response?.data?.errors === "object" && error !== null) {
      for (const [, value] of Object?.entries(error?.response?.data?.errors)) {
        showNotification({
          message: `${value}`,
          color: "red",
        });
      }
    } else {
      showNotification({
        title: "Error",
        message: error?.response?.data?.message ?? "",
        color: "red",
      });
    }
  };
  return {
    handleError,
    logoutUser,
    handleSuccess,
  };
};

export default useNotification;
