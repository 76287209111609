import React, { useState, useContext, useEffect } from "react";
import Layout from "../components/LoggedIn/Layout";
import FormInput from "../components/FormComponent/Input";
import { DataContext } from "../contexts/Data/DataContext";
import { useNavigate } from "react-router-dom";
import usePatient from "../hooks/usePatient";

const Patientinformation = (props) => {
  const { patientinformation, setPatientInformation, phoneNumber, setPhoneNumber, patient } = useContext(DataContext);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { getPatientByNumber } = usePatient();
  console.log({patientInfo:patient})

  const { email, firstname, lastname, phonenumber, age, bloodgroup, dateofbirth, weight, height } = patientinformation;

  const naivgateToDashboard = () => {
    navigate("/");
  };

  const changeHandler = (e) => {
    setPatientInformation({
      ...patientinformation,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (patient?.documents?.length > 0) {
      setPatientInformation({
        ...patientinformation,
        email: patient?.documents[0]?.email,
        firstname: patient?.documents[0]?.first_name,
        lastname: patient?.documents[0]?.last_name,
        phonenumber: patient?.documents[0]?.phone_number,
        age: patient?.documents[0]?.age,
        bloodgroup: patient?.documents[0]?.blood_type,
        dateofbirth: patient?.documents[0]?.dob,
        weight: patient?.documents[0]?.weight,
        height: patient?.documents[0]?.height,
      });
      setShowModal(false);
    }
  }, [patient]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    getPatientByNumber(phoneNumber, setError, setMessage);
    
  };

  return (
    <Layout showHeading>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden fixed overflow-y-auto inset-0 z-50 outline-none focus:outline-none backdrop-blur'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 rounded-t'>
                  {/* <h3 className="text-3xl font-semibold">Modal Title</h3> */}
                  <button
                    onClick={() => {
                      naivgateToDashboard();
                    }}
                    className='p-1 ml-auto bg-transparent border-0 text-[#EAEAEA]  float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                  >
                    <span className='bg-transparent text-[#EAEAEA] h-6 w-6 text-2xl block outline-none focus:outline-none'>×</span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className='relative p-6 flex-auto'>
                    <div className='mb-2 my-4 text-slate-500 text-lg leading-relaxed w-96'>
                      <label className='block text-[#151515] font-semibold font-commissioner mb-2' htmlFor='phoneNumber'>
                        Patients’ Phone Number
                      </label>
                      {error && <span className='text-md text-[#F2766D]'>{message}</span>}
                      <input
                        className='appearance-none border rounded sm:w-[20rem] lg:w-full py-3 px-3 text-[#999999] leading-tight focus:outline-none focus:shadow-outline'
                        id='phoneNumber'
                        type='number'
                        name='phone_number'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder='Enter phone number'
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end p-6  rounded-b'>
                    <button
                      className={
                        error
                          ? "bg-white text-[#F44336] border border-solid border-[#F44336] w-full font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          : "bg-[#F44336] text-white w-full font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type='submit'
                    >
                      {error ? "Try Again" : "Continue"}
                      {loading && (
                        <svg
                          className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
      <div className=' text-center md:text-start px-8 md:px-0 pr-0 md:pr-8 grid-none cols-none md:grid grid-cols-2 placeholder:center'>
        {/* firstname */}
        <div className='pr-8 pb-12'>
          <label htmlFor='first-name' className='block text-sm  font-medium  text-consultblack'>
            First Name
          </label>

          <FormInput name={"firstname"} type='text' value={firstname} placeholder='Lhezie' required='yes' handleChange={changeHandler} size='' />
        </div>

        {/* last name */}
        <div className=' pr-6 md:pr-8 lg:pr-0 pb-12'>
          <div>
            <label htmlFor='lastname' className='block text-sm  font-medium  text-consultblack'>
              Last Name
            </label>
            <FormInput
              disabled
              name={"lastname"}
              type='text'
              value={lastname}
              placeholder='Andy'
              required='yes'
              handleChange={changeHandler}
              size=''
            />
          </div>
        </div>

        {/* phone No */}
        <div className='pr-8 pb-12'>
          <label htmlFor='Phonenumber' className='block text-sm  font-medium  text-consultblack'>
            Phone Number
          </label>

          <FormInput
            name={"phonenumber"}
            disabled
            type='text'
            value={phonenumber}
            placeholder='Phone number'
            required='yes'
            handleChange={changeHandler}
            size=''
          />
        </div>

        {/* email */}
        <div>
          <div className=' pr-6 md:pr-6 lg:pr-0 pb-6'>
            <label htmlFor='email' className='block text-sm  font-medium  text-consultblack'>
              Patient Email
            </label>
            <FormInput
              disabled
              name={"email"}
              type='email'
              value={email}
              placeholder='someone@mail.com'
              required='yes'
              handleChange={changeHandler}
              size=''
            />
          </div>
        </div>

        {/* age */}
        <div className='pr-8 pb-12'>
          <label htmlFor='age' className='block text-sm  font-medium  text-consultblack'>
            Age
          </label>

          <FormInput disabled name={"age"} type='text' value={age} placeholder='28' required='yes' handleChange={changeHandler} size='' />
        </div>

        {/* DOB */}
        <div className=''>
          <div className=' pr-6 md:pr-6 lg:pr-0 pb-8 '>
            <label htmlFor='DOB' className='block text-sm  font-medium  text-consultblack'>
              Date of birth
            </label>
            <FormInput
              disabled
              name={"dateofbirth"}
              type='date'
              value={dateofbirth}
              placeholder=''
              required='yes'
              handleChange={changeHandler}
              size=''
            />
          </div>
        </div>

        {/* blood group */}
        <div className='pr-8 pb-12'>
          <label htmlFor='blood-group' className='block text-sm  font-medium  text-consultblack'>
            Blood group
          </label>

          <FormInput disabled name={"bloodgroup"} type='text' value={bloodgroup} required='yes' handleChange={changeHandler} size='' />
        </div>

        <div>
          {/* weight */}
          <div className=' pr-6 md:pr-6 lg:pr-0 flex justify-center md:justify-start'>
            <div className=''>
              <label htmlFor='weight' className='mt-2 block text-sm  font-medium  text-consultblack'>
                Weight
              </label>
              <FormInput
                name={"weight"}
                type='text'
                disabled
                value={weight}
                required='yes'
                handleChange={changeHandler}
                size='w-12 md:w-20 h-10 text-center rounded-none '
              />
            </div>

            {/* height */}
            <div className='px-14 '>
              <label htmlFor='height' className='mt-2 block text-sm  font-medium  text-consultblack'>
                Height
              </label>
              <FormInput
                name={"height"}
                type='text'
                value={height}
                required='yes'
                handleChange={changeHandler}
                size='w-12 md:w-20 h-10 text-center rounded-none'
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Patientinformation;
