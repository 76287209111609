import React, { useEffect, useState } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { UserContext } from "../contexts/User/UserContext";
import usePatient from "../hooks/usePatient";




export default function VideoCall(){

  function getUrlParams(url = window.location.href) {
    let urlStrArray = url.split("/");
    let urlStr = urlStrArray[urlStrArray.length - 1];
    return urlStr;
  }

  const [docID, setDocID]  = useState(() => getUrlParams())
  const [docName, setDocName] = useState()

  const {useGetDoctorsInfo} = usePatient()

   const {doctor} =  useGetDoctorsInfo(docID)


   console.log("doctor===>", doctor)

  

  return (
    <div>
      {doctor && Object.keys(doctor)?.length > 0 &&  docID && <VideoComponent docID = {docID}  doctor = {doctor} />}
    </div>
  )
}



function randomID(len) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

 function VideoComponent({docID, doctor}) {

  const roomID = docID

  const userID = randomID(5);
  const userName = `${doctor.first_name} ${doctor.last_name}`;

  let myMeeting = async (element) => {
    // generate Kit Token
    let appID = Number(process.env.REACT_APP_ZEGO_APP_ID)
    let serverSecret = process.env.REACT_APP_ZEGO_SERVER_SECRET;

    // let appID = 698156164
    // let serverSecret = "1b7af56b16806cad1687269a64ec8015"


    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: "Personal link",
          url: window.location.protocol + "//" + window.location.host + window.location.pathname + "?roomID=" + roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });
  };

  return <div className="myCallContainer" ref={myMeeting} style={{ width: "100vw", height: "100vh" }}></div>;
}













/**
 * import React, { useEffect, useState } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { UserContext } from "../contexts/User/UserContext";

function randomID(len) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

function getUrlParams(url = window.location.href) {
  let urlStrArray = url.split("/");
  let urlStr = urlStrArray[urlStrArray.length - 1];
  return urlStr;
}

function getUsername() {
  const url = window.location.href;
  let urlStrArray = url.split("/");
  let urlStr = urlStrArray[urlStrArray.length - 1];
  console.log("urlStr ==> ", urlStr);
  return urlStr;
}

export default function App() {
  const { userDetails } = React.useContext(UserContext);
  // const [userName, setUserName] = useState(getUsername())

  const roomID = getUrlParams() || randomID(5);
  // const roomID = getUrlParams().get("roomID")

  // userDetails?.providerUid
  // const roomID = randomID(5); //room || randomID(5)
  const userID = randomID(5);
  const userName = randomID(5);

  let myMeeting = async (element) => {
    // generate Kit Token
    let appID = 698156164;
    let serverSecret = "1b7af56b16806cad1687269a64ec8015";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: "Personal link",
          url: window.location.protocol + "//" + window.location.host + window.location.pathname + "?roomID=" + roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });
  };

  return <div className="myCallContainer" ref={myMeeting} style={{ width: "100vw", height: "100vh" }}></div>;
}

 */




/*
export default function App() {
  let myMeeting = () => {
    function getUrlParams(url) {
      let urlStr = url.split("?")[1];
      const urlSearchParams = new URLSearchParams(urlStr);
      const result = Object.fromEntries(urlSearchParams.entries());
      return result;
    }

    // Generate a Token by calling a method.
    // @param 1: appID
    // @param 2: serverSecret
    // @param 3: Room ID
    // @param 4: User ID
    // @param 5: Username
    // getUrlParams(window.location.href)["roomID"] ||
    const roomID =  Math.floor(Math.random() * 10000) + "";
    const userID = Math.floor(Math.random() * 10000) + "";
    const userName = "userName" + userID;
    const appID = 698156164;
    const serverSecret = "1b7af56b16806cad1687269a64ec8015";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);

    console.log("roomID", roomID)

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: document.querySelector("#root"),
      sharedLinks: [
        {
          name: "Personal link" + roomID,
          url: window.location.protocol + "//" + window.location.host + window.location.pathname + "?roomID=" + roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference,
      },

      turnOnMicrophoneWhenJoining: true,
      turnOnCameraWhenJoining: true,
      showMyCameraToggleButton: true,
      showMyMicrophoneToggleButton: true,
      showAudioVideoSettingsButton: true,
      showScreenSharingButton: true,
      showTextChat: true,
      showUserList: true,
      maxUsers: 2,
      layout: "Auto",
      showLayoutButton: false,
    });
  };



  useEffect(() => {
    myMeeting()
  }, [])

  return <div id="root" style={{ width: "100vw", height: "100vh" }}></div> 
}
*/
