import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Client, Account, ID, Databases } from "appwrite";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useDoctor from "../../hooks/useDoctor";
import useNotification from "../../hooks/useNotification";

const Registerform = () => {
  const { createDoctorAccounnt } = useDoctor();
  const { handleError, handleSuccess } = useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    // specialization: Yup.string().required("Please enter your organization"),
    designation: Yup.string().required("Please enter the designation"),
    first_name: Yup.string().required("Please enter your first name"),
    last_name: Yup.string().required("Please enter your last name"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const submitHandler = async (data) => {
    console.log(data);
    try {
      const res = await createDoctorAccounnt(data);
      console.log(res);
      reset();
      // navigate("/login");
    } catch (error) {
      console.log(error.message);

      return;
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <br />
      <div className="col-span-6 sm:col-span-3">
        {/* firstname */}
        <label
          htmlFor="firstName"
          className="block text-sm font-medium text-nextgray "
        >
          First Name
        </label>
        <input
          name={"first_name"}
          {...register("first_name")}
          placeholder="someone"
          required="Required"
          autoComplete="first_name"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm  border-bordergray border placeholder:text-[14px] placeholder:text-gray-500 sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">
          {errors.first_name?.message}
        </p>

        {/* lastname */}
        <label className="block text-sm font-medium text-nextgray pt-2">
          Last Name
        </label>
        <input
          name={"last_name"}
          {...register("last_name")}
          placeholder="someone"
          required="Required"
          autoComplete="last_name"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm   border-bordergray border placeholder:text-[14px] placeholder:text-gray-500   sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">
          {errors.last_name?.message}
        </p>

        {/* email */}
        <label className="block text-sm font-medium text-nextgray pt-2">
          Email Address
        </label>
        <input
          name={"email"}
          {...register("email")}
          type="email"
          required="Required"
          autoComplete="email"
          placeholder="someone@example.com"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm   border-bordergray border placeholder:text-[14px] placeholder:text-gray-500  sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">{errors.email?.message}</p>

        {/* password */}

        <label className="block text-sm font-medium text-nextgray pt-2">
          Create Password
        </label>
        <input
          name={"password"}
          {...register("password")}
          type="password"
          required="Required"
          autoComplete="password"
          placeholder="someone@example.com"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm  border-bordergray border placeholder:text-[14px] placeholder:text-gray-500  sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">
          {errors.password?.message}
        </p>

        {/* Organization */}
        <label className="block text-sm font-medium text-nextgray pt-2">
          Specialization
        </label>
        <input
          name={"specialization"}
          {...register("specialization")}
          placeholder="specialization"
          required="Required"
          autoComplete="specialization"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm  border-bordergray border  placeholder:text-[14px] placeholder:text-gray-500  sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">
          {errors.specialization?.message}
        </p>

        {/* designation */}
        <label className="block text-sm font-medium text-nextgray pt-2">
          Designation
        </label>
        <input
          name={"designation"}
          {...register("designation")}
          placeholder=""
          required="Required"
          autoComplete="designation"
          className="mt-1 block w-full h-12 rounded-md text-left pl-2 text-sm   border-bordergray border  placeholder:text-[14px] placeholder:text-gray-500  sm:text-sm"
        />
        <p className="text-[13px] mt-2 text-red-400">
          {errors.designation?.message}
        </p>

        <input type="checkbox" />
        <span className="text-nextgray pl-6 font-medium">Remember me</span>
      </div>

      {/* Login button */}
      <br />
      <button
        type="submit"
        className="bg-gradient-to-r from-red-500  via-red-600 to-red-700 inline-flex justify-center w-full h-12 rounded-lg text-white py-3"
      >
        Login
      </button>
      <br />

      <br />
      <div className="text-center">
        <h4 className="inline">Have an account?</h4>
       <Link to="/login"> <h5 className="ml-4 inline text-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
          Sign in
        </h5></Link>
      </div>
    </form>
  );
};

export default Registerform;
