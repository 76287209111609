import { useContext, useState } from "react";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import {
  createNewDoctor,
  createNewDoctorUserAccount,
  createNewDoctorr,
  createNewDoctorUserAccountt,
  updateDoctorData,
} from "../services/Dotocrs/Doctors";
import { getAllDoctors } from "../utils/appwrite";
import useNotification from "./useNotification";

const useDoctor = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allDoctors, setAllDoctors] = useState([]);
  const [doctor, setDoctor] = useState(null);

  const createDoctorAccount = async (data) => {
    setShowLoader(true);
    try {
      createNewDoctorUserAccount(
        data.email,
        data.password,
        data.first_name,
        data.last_name
      )
        .then((res) => {
          console.log(res.$id);
          console.log(res);
          createNewDoctor(
            res.$id,
            data.first_name,
            data.last_name,
            data.designation,
            data.specialization,
            data.email
          );
          console
            .log(res.$id)
            .then((res) => {
              handleSuccess("Doctor account created successfully");
            })
            .catch((error) => {
              handleError(error);
            });
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const createDoctorAccounnt = async (data) => {
    setShowLoader(true);
    try {
      const res = await createNewDoctorUserAccountt(
        data.email,
        data.password,
        data.first_name,
        data.last_name
      );

      console.log({ res });

      if (!res.$id) throw new Error(`account failed to be created`);
      const createRes = await createNewDoctorr(
        res.$id,
        data.first_name,
        data.last_name,
        data.designation,
        // specialization:data.specialization,
        data.email
        // organization: data.organization
      );
      console.log({ createRes });
      handleSuccess("Doctor account created successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const createDoctorAccountt = async (data) => {
    setShowLoader(true);
    console.log(data);
    try {
      const acctRes = await createNewDoctorUserAccount(
        data.email,
        data.password,
        data.first_name,
        data.last_name
      );
      console.log({ acctRes });
      if (!acctRes.$id) throw new Error(`account failed to be created`);
      const createRes = await createNewDoctor({
        userID: acctRes.$id,
        first_name: data.firstname,
        last_name: data.lastname,
        designation: data.designation,
        // specialization:data.specialization,
        email: data.email,
        // organization: data.organization
      });
      console.log({ createRes });
      handleSuccess("Doctor account created successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const getAllDoctorsList = async () => {
    const doctors = await getAllDoctors();
    console.log({ doctors });
    setAllDoctors(doctors.documents);
  };

  const updateDoctor = async (id, data) => {
    setShowLoader(true);
    console.log("udpate in usePatient", id, data);
    try {
      const updateRes = await updateDoctorData(id, data);
      console.log({ updateRes });
      if (!updateRes.$id) throw new Error(`Doctor failed to be updated`);
      handleSuccess("Doctor updated successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  return {
    allDoctors,
    doctor,
    createDoctorAccounnt,
    createDoctorAccountt,
    setDoctor,
    setAllDoctors,
    updateDoctor,
    getAllDoctorsList,
    createDoctorAccount,
  };
};

export default useDoctor;
