import { getAllScheduler, updateScheduler} from "../../utils/appwrite";

export const getScheduler = () => {
  return getAllScheduler().then((res) => {
    return res;
  });
};


export const updateSchedulerData = (id, data) => {
  return updateScheduler().then(id, data).then((res) => {
    return res;
  });
};




