import React from "react";
import Union from "../photos/Union.png";

const Cancel = () => {
  return (
    <div className='w-3 items-center mt-4 ml-2'>
      <img src={Union} alt='' />
    </div>
  );
};

export default Cancel;
