import { useContext } from "react";
import useNotification from "./useNotification";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import { getAllChat, getChatMessages, sendChatMessage } from "../services/chat/chat";

const useChat = () => {
  const { handleError } = useNotification();
  const { setShowLoader } = useContext(UtilityContext);

  const getChat = (sender_id) => {
    return new Promise((resolve) => {
      setShowLoader(true);
      getAllChat(sender_id)
        .then((res) => {
          resolve(res);
        })
        .finally(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          handleError(error);
          setShowLoader(false);
        });
    });
  };

  const sendMessage = ({ message, sender_id, to_id, chatID, message_sent }) => {
    return new Promise((resolve) => {
      setShowLoader(true);
      sendChatMessage(message, sender_id, to_id, chatID, message_sent)
        .then((res) => {
          resolve(res);
        })
        .finally(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          handleError(error);
          setShowLoader(false);
        });
    });
  };

  const getMessages = (chat_id) => {
    return new Promise((resolve) => {
      setShowLoader(true);
      getChatMessages(chat_id)
        .then((res) => {
          resolve(res);
        })
        .finally(() => {
          setShowLoader(false);
        })
        .catch((error) => {
          handleError(error);
          setShowLoader(false);
        });
    });
  };

  return { getChat, sendMessage, getMessages };
};

export default useChat;
