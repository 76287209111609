import React, { useContext, useEffect, useRef } from "react";
import Layout from "../components/LoggedIn/Layout";
import { AiOutlineSend } from "react-icons/ai";
import useChat from "../hooks/useChat";
import { UserContext } from "../contexts/User/UserContext";
import { Client } from "appwrite";
import moment from "moment";

const Chat = (props) => {
  const client = new Client().setEndpoint(process.env.REACT_APP_API).setProject(process.env.REACT_APP_PROJECT_KEY);

  const { doctorDetails } = useContext(UserContext);
  const [chats, setChats] = React.useState([]);
  const [activeChat, setActiveChat] = React.useState({
    $id: "",
    name: "",
    patient_ID: "",
  });
  const [messages, setMessages] = React.useState([]);
  const { getChat, getMessages, sendMessage } = useChat();
  const inputRef = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    getChat(doctorDetails?.$id).then((res) => {
      setChats(res?.documents);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorDetails?.$id]);

  useEffect(() => {
    const unsubscribe = client.subscribe(["databases.6362767a15ab7b1e0c30.collections.63a45e3c9c1617f19dc5.documents"], (data) => {
      if (data.payload?.chatID === activeChat.$id) {
        console.log("worked", data.payload);
        setMessages((prev) => [...prev, data.payload]);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [activeChat?.$id]);
  console.log(messages);
  const ChangesetActiveChat = (name, id) => {
    if (activeChat.$id === id) return;
    setActiveChat({
      $id: id,
      name: name,
      patient_ID: id,
    });
    getMessages(id).then((res) => {
      console.log(res);
      setMessages(res?.documents);
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  };

  async function sendChatMessage(e) {
    e.preventDefault();
    if (!e.target.message.value) return;
    const messageData = {
      message: e.target.message.value,
      sender_id: doctorDetails?.$id,
      to_id: activeChat?.patient_ID,
      chatID: activeChat?.$id,
      message_sent: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    sendMessage(messageData).then((res) => {
      // setMessage("");
      inputRef.current.value = "";
    });
  }

  return (
    <Layout>
      <div className='h-screen w-full -mb-10'>
        <div className='flex h-full'>
          <div className='h-full w-full flex-1 bg-gray-50'>
            <div className='main-body container m-auto flex h-full w-11/12 flex-col'>
              <div className='main flex flex-1 flex-col'>
                <div className='heading flex-2 hidden lg:block'>
                  <h1 className='mb-4 text-3xl text-gray-700'>Chat</h1>
                </div>
                <div className='flex lg:hidden overflow-auto px-6 gap-4 py-3'>
                  {chats?.map((chat) => (
                    <ChatUser key={chat.$id} user={chat} ChangesetActiveChat={ChangesetActiveChat} />
                  ))}
                </div>
                <div className='flex h-full flex-1'>
                  <div className='sidebar flex-2 hidden w-1/3 flex-col pr-6 lg:flex'>
                    <div className='search flex-2 px-2 pb-6'>
                      <input type='text' className='block w-full border-b-2 border-gray-200 bg-transparent py-2 outline-none' placeholder='Search' />
                    </div>
                    <div className='max-h-96 flex-1 overflow-auto px-2'>
                      {chats?.map((chat) => (
                        <ChatUser key={chat.$id} user={chat} ChangesetActiveChat={ChangesetActiveChat} />
                      ))}
                    </div>
                  </div>
                  <MessageBox
                    activeChat={activeChat}
                    messages={messages}
                    chat={activeChat}
                    sendMessage={sendChatMessage}
                    bottomRef={bottomRef}
                    inputRef={inputRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;

function ChatUser({ user, ChangesetActiveChat }) {
  return (
    <div
      onClick={() => {
        ChangesetActiveChat(user?.patient_name, user?.$id, user?.patient_ID);
      }}
      className='entry mb-4 flex transform cursor-pointer rounded bg-white p-4 shadow-md transition-transform duration-300 hover:scale-105'
    >
      <div className='flex-2'>
        <div className='relative h-12 w-12'>
          <img
            className='mx-auto h-12 w-12 rounded-full'
            src={`https://ui-avatars.com/api/?name=${user?.patient_name}&background=B21E14&color=fff`}
            alt='chat-user'
          />
          <span className='absolute right-0 bottom-0 h-4 w-4 rounded-full border-2 border-white bg-green-400'></span>
        </div>
      </div>
      <div className='flex-1 px-2'>
        <div className='w-32 truncate'>
          <span className='text-gray-800'>{user?.patient_name}</span>
        </div>
        <div>
          <small className='text-gray-600'>Yea, Sure!</small>
        </div>
      </div>
      <div className='flex-2 text-right'>
        <div>
          <small className='text-gray-500'>15 April</small>
        </div>
        <div>
          <small className='inline-block h-6 w-6 rounded-full bg-red-500 text-center text-xs leading-6 text-white'>1</small>
        </div>
      </div>
    </div>
  );
}

function MyMessage({ message }) {
  return (
    <div className='message me mb-4 flex text-right'>
      <div className='flex-1 px-2'>
        <div className='inline-block rounded-full bg-blue-600 p-2 px-6 text-white'>
          <span>{message?.message}</span>
        </div>
        <div className='pr-4'>
          <small className='text-gray-500'>{moment(message?.$createdAt).format("DD MMMM")}</small>
        </div>
      </div>
    </div>
  );
}

function OtherMessages({ chat, message }) {
  return (
    <div className='message mb-4 flex'>
      <div className='flex-2'>
        <div className='relative h-12 w-12'>
          <img
            className='mx-auto h-12 w-12 rounded-full'
            src={`https://ui-avatars.com/api/?name=${chat?.name}&background=B21E14&color=fff`}
            alt='chat-user'
          />{" "}
          <span className='absolute right-0 bottom-0 h-4 w-4 rounded-full border-2 border-white bg-gray-400'></span>
        </div>
      </div>
      <div className='flex-1 px-2'>
        <div className='inline-block rounded-full bg-gray-300 p-2 px-6 text-gray-700'>
          <span>{message?.message}</span>
        </div>
        <div className='pl-4'>
          <small className='text-gray-500'>{moment(message?.$createdAt).format("DD MMMM")}</small>
        </div>
      </div>
    </div>
  );
}

function MessageBox({ chat, activeChat, messages, sendMessage, bottomRef, inputRef }) {
  const { doctorDetails } = useContext(UserContext);

  return (
    <div className='chat-area flex flex-1 flex-col'>
      <div className='flex-3'>
        <h2 className='mb-8 border-b-2 border-gray-200 py-1 text-xl'>
          {activeChat?.name && (
            <span className='text-gray-700'>
              Chat with <b>{activeChat?.name}</b>
            </span>
          )}
        </h2>
      </div>
      {activeChat?.name && (
        <>
          <div className='messages overflow-auto max-h-[320px] lg:max-h-[420px]'>
            {messages ? (
              <>
                {messages?.map((message) =>
                  message?.sender_id === doctorDetails?.$id ? (
                    <MyMessage key={message?.$id} message={message} chat={chat} />
                  ) : (
                    <OtherMessages key={message?.$id} message={message} chat={chat} />
                  )
                )}
                <div className='bottom' ref={bottomRef}></div>
              </>
            ) : (
              <div className='flex-1 flex justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                  <div className='flex flex-col justify-center items-center'>
                    <img src='/images/empty-chat.svg' alt='empty-chat' className='w-32' />
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <h2 className='text-gray-500 text-xl'>No messages yet</h2>
                    <p className='text-gray-500 text-sm'>Send a message to start a conversation</p>
                    <button className='bg-blue-600 text-white px-4 py-2 rounded-lg mt-4'>Send Message</button>
                  </div>
                </div>
              </div>
            )}
            <div className='bottom' ref={bottomRef}></div>
          </div>
          <div className='flex-2 pt-4 pb-10'>
            <form className=' w-full' onSubmit={sendMessage}>
              <div className='write flex rounded-lg bg-white shadow'>
                <div className='flex-3 flex content-center items-center p-4 pr-0 text-center'></div>
                <div className='flex-1'>
                  <input
                    name='message'
                    className='block w-full bg-transparent py-4 px-4 outline-none ring-0 resize-none border-1 rounded-lg border-gray-50 focus:ring-0 overflow-none'
                    placeholder='Type a message...'
                    autoFocus
                    ref={inputRef}
                  />
                </div>
                <div className='flex-2 flex w-32 content-center items-center p-2'>
                  <div className='flex-1 text-center'></div>
                  <div className='flex-1'>
                    <button type='submit' className='inline-block h-10 w-10 rounded-full bg-nextred'>
                      <span className='inline-block align-text-bottom'>
                        {/* <AiOutlineSend className='text-white h-4 w-4' />
                         */}
                        <svg className='arrow' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M13.0737 3.06325C12.8704 2.65671 12.4549 2.3999 12.0004 2.3999C11.5459 2.3999 11.1304 2.65671 10.9271 3.06325L2.52709 19.8632C2.31427 20.2889 2.37308 20.8001 2.67699 21.1663C2.98091 21.5325 3.4725 21.6845 3.93007 21.5537L9.93006 19.8395C10.4452 19.6923 10.8004 19.2214 10.8004 18.6856V13.1999C10.8004 12.5372 11.3376 11.9999 12.0004 11.9999C12.6631 11.9999 13.2004 12.5372 13.2004 13.1999V18.6856C13.2004 19.2214 13.5556 19.6923 14.0707 19.8394L20.0707 21.5537C20.5283 21.6845 21.0199 21.5325 21.3238 21.1663C21.6277 20.8001 21.6865 20.2889 21.4737 19.8632L13.0737 3.06325Z'
                            fill='#373B4D'
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}
