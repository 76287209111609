import React, { useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { MantineProvider, LoadingOverlay } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
//
import { UtilityContext } from "./contexts/Utility/UtilityContext";
//
import Forgetpassword from "./pages/PasswordRecovery";
import PasswordRecoveryConfirmation from "./pages/PasswordRecoveryConfirmation"
import Login from "./pages/Login.jsx";
import Signup from "./pages/Signup.jsx";
import LoggedInContainer from "./components/LoggedIn";

//

import { AppointmentProvider } from "./contexts/Form/AppointmentContext";
import { ScheduleCalendarProvider } from "./contexts/Form/ScheduleCalendarContext";
import GeneralProvider from "./contexts/Form/GeneralContext";
import ShowProvider from "./contexts/Form/ShowContext";
import ViewSearchProvider from "./contexts/Form/ViewSearch";
import SecondTestPage from "./pages/VideoCall";

//

function App() {
  const token = localStorage.getItem("doci_doctor_user");
  const { showLoader } = useContext(UtilityContext);

  return (
    <>
      <MantineProvider
        theme={{
          defaultRadius: 5,
          colors: {
            "crm-blue": ["#E7F5FF", "#D0EBFF", "#A5D8FF", "#74C0FC", "#4DABF7", "#339AF0", "#0177B7", "#1C7ED6", "#1971C2", "#1864AB"],
          },
          primaryColor: "crm-blue",
        }}
      >
        <LoadingOverlay visible={showLoader} />

        <ViewSearchProvider>
          <ScheduleCalendarProvider />
          <ShowProvider>
            <GeneralProvider>
              <AppointmentProvider>
                <NotificationsProvider position='top-right' zIndex={2077} autoClose={3000}>
                  <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot-password' element={<Forgetpassword/>} />
                    <Route path='/password_recovery' element={<PasswordRecoveryConfirmation/>} />
                    <Route path='/register' element={<Signup />} />
                    <Route path='/*' element={token ? <LoggedInContainer /> : <Login />} />
                    
                  </Routes>
                </NotificationsProvider>
              </AppointmentProvider>
            </GeneralProvider>
          </ShowProvider>
          <ScheduleCalendarProvider />
        </ViewSearchProvider>
      </MantineProvider>
    </>
  );
}

export default App;
