import React, { useState, useContext, useEffect } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { BsArrowUp, BsArrowDown, BsFillCameraVideoFill } from "react-icons/bs";
import { generalContext } from "../../contexts/Form/GeneralContext";
import { DataContext } from "../../contexts/Data/DataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//
import Pagnation from "./Pagnation";
import usePatient from "../../hooks/usePatient";
import useConsultations from "../../hooks/useConsultations";

import moment from "moment";
import { UserContext } from "../../contexts/User/UserContext";

export const Cards = () => {
  const { getAllPatientsList, getAllPatientsAppoinments } = usePatient();
  const { allConsultations, getAllConsultationList } = useConsultations();

  const { userDetails, doctorDetails } = useContext(UserContext);

  console.log(userDetails)

  const { allPatients, allAppointments, showModalOne, setShowModalOne, clickedAppointment, setClickedAppointment } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAllPatientsList();
    getAllPatientsAppoinments();
    getAllConsultationList();
  }, []);

  const getAppointmentDetails = (id) => {
    const appointment = allAppointments?.find((appointment) => appointment?.$id === id);
    navigate(`/video/${appointment?.$id}`);
  };

  const gotoVideoCall = (id) => {
    navigate(`/videocall/${id}`);
    //navigate(`/videocall/${id}/${doctorDetails?.first_name}-${doctorDetails?.last_name}`);
    
  };

  const handleAppointmentClick = (id) => {
    setClickedAppointment(id);
    setShowModalOne(true);
  };
  console.log("allAppointments", allAppointments);
  
  console.log("moment",moment(allAppointments?.[0]?.date))
  console.log("moment now",moment().format("hh:mm:ss"))


  return (
    <div>
      <div className="mt-8 grid-none cols-none md:grid gap-9 grid-cols-3 px-8 md:px-0  w-full">
        {/* all patients */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
          <div className="flex justify-end mt-5 mr-3">
            <div className="flex w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              <BsArrowUp className=" text-greentext ml-6 font-bold" />
              <div className="text-greentext font-medium text-[15px]">31%</div>
            </div>
          </div>
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">All Patients</div>
            <div className="mt-3 px-3 text-sm mb-6 text-cardtextcl">{allPatients?.length} patients</div>
          </div>
        </div>

        {/*  total appointment*/}
        <div className=" border border-navgray shadow-md rounded-lg pr-6">
          <div className="flex justify-end mt-5 mr-3">
            <div className="flex w-20 rounded-l-full rounded-r-full bg-brownybg h-6 items-center border-5">
              <BsArrowDown className=" text-brownytext ml-6 font-bold" />
              <div className="text-brownytext font-medium  text-[15px]">-14%</div>
            </div>
          </div>
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3 ">Total Consultation</div>
            <div className="mt-3 px-3 text-sm mb-6 text-cardtextcl">{allConsultations?.length} consultation</div>
          </div>
        </div>

        {/* total earning */}
        <div className="border border-navgray shadow-md  rounded-lg ">
          <div className="flex justify-end mt-5 mr-3">
            <div className="flex w-20 rounded-l-full rounded-r-full bg-brownybg h-6 items-center border-5">
              <BsArrowUp className=" text-brownytext ml-6 font-bold" />
              <div className="text-brownytext font-medium  text-[15px]">14%</div>
            </div>
          </div>
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3 ">Total Appointment</div>
            <div className="mt-3 px-3 text-sm mb-6 text-cardtextcl">{allAppointments?.length} appointments</div>
          </div>
        </div>
      </div>

      <div className="pt-10 pl-8 md:pl-0 pr-8 md:pr-0 ">
        <div className="shadow-md">
          <div className=" flex justify-between pr-12 w-full h-12 border border-navgray rounded-t-lg items-center">
            <div>
              <div className="text-sidetextbg pl-6">Upcoming appointment</div>
            </div>
          </div>

          {/* table */}
          <div className="w-full h-fit border border-navgray ">
            <div className="overflow-x-auto w-full">
              <table className="table w-full">
                {/* <!-- head --> */}
                <thead>
                  <tr className="bg-pink-200">
                    <th className="bg-pink-200">
                      <div className="bg-pink-200">#</div>
                    </th>
                    <th className="bg-pink-200">Patients</th>
                    <th className="bg-pink-200">Appointments</th>
                    <th className="bg-pink-200">Age</th>
                    <th className="bg-pink-200">Conditions</th>
                    <th className="bg-pink-200">Symptoms</th>
                    {/* <th className='bg-pink-200'>Time</th> */}
                    <th className="bg-pink-200">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <!-- row 1 --> */}
                  {allAppointments
                    ?.filter((item) => item.status === "upcoming" || item.doctorID === userDetails?.$id)
                    ?.map((item, index) => {
                      let userImage = item.patientImage || `https://ui-avatars.com/api/?name=${item.patientName}&background=B21E14&color=fff`;
                      return (
                        <tr key={index}>
                          <th>
                            <label>
                              <div>{index + 1}</div>
                            </label>
                          </th>
                          <td>
                            <div className="flex items-center space-x-3">
                              <div className="avatar">
                                <div className="mask mask-squircle w-12 h-12">
                                  <img src={userImage} alt="images" />
                                </div>
                              </div>
                              <div>
                                <div className="font-bold">{item.patientName}</div>
                                <div className=" opacity-50 text-sm text-nextgray text-center ">Returning Patient</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={`py-2 w-[200px] rounded-md centerFlex  cursor-pointer border border-1 ${!item?.doctorID ? "border-red-500 text-red-500" : " border-green-300 text-green-300"}`} onClick={() => handleAppointmentClick(item.$id)}>
                              {!item?.doctorID ? "Pending Request" : "Upcoming Appointment"}
                            </div>
                          </td>
                          <th>
                            <div className="btn btn-ghost btn-xs">28</div>
                          </th>
                          <td>{item.description}</td>
                          <td>{item.symptoms}</td>
                          {/* <td>
                          {moment(item.date).format("DD MMM YYYY")}
                          <br />
                          <span className='text-sm text-nextgray text-center '>{moment(item.date).format("hh:mm A")}</span>
                        </td> */}
                          <td>
                            {/* <BsFillCameraVideoFill
                            className="text-2xl animate-pulse text-nextred"
                            onClick={() => {
                              getAppointmentDetails(item.$id);
                              gotoVideoCall(item.$id, item.date)
                            }}
                          /> */}
                            {/* difference from item date to now */}
                            {/* {moment(item.date).diff(moment(), "days") > 0 ? (
                              <div className="btn btn-ghost btn-xs">Pending</div>
                            ) : moment(item.date).diff(moment(), "minutes") <= 15 && moment(item.date).diff(moment(), "minutes") > -10 ? (
                              <div className="btn btn-ghost btn-xs">
                                <BsFillCameraVideoFill
                                  className="text-2xl animate-pulse text-nextred"
                                  onClick={() => {
                                    // getAppointmentDetails(item.$id);
                                    if(item.doctorID === userDetails?.$id) {
                                      gotoVideoCall(userDetails?.$id);
                                    }
                                    else {
                                      toast.warn('Please accept appointment request')
                                    }
                                  }}
                                />
                              </div>
                            ) : moment(item.date).diff(moment(), "minutes") > 15 ? (
                              <div className="btn btn-ghost btn-xs">Upcoming</div>
                            ) : (
                              <div className="btn btn-ghost btn-xs">Missed</div>
                            )} */}

                             <div className="btn btn-ghost btn-xs">
                               <BsFillCameraVideoFill
                                 className="text-2xl animate-pulse text-nextred"
                                 onClick={() => {
                                   // getAppointmentDetails(item.$id);
                                   console.log("item", item)
                                   if (item.doctorID === userDetails?.$id) {
                                     gotoVideoCall(userDetails?.$id);
                                   } else {
                                     toast.warn("Please accept appointment request");
                                   }
                                 }}
                               />
                             </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Pagnation />
      </div>
    </div>
  );
};

const VideoReminder = ({ gotoVideoCall, item }) => {
  return (
    <BsFillCameraVideoFill
      className="text-2xl animate-pulse text-nextred"
      onClick={() => {
        // getAppointmentDetails(item.$id);
        gotoVideoCall(item.$id, item.date);
      }}
    />
  );
};
