import React from "react";

const InputArea = ({
  name,
  placeholder,
  value,
  type,
  required,
  register,
  handleChange,
  area,
  size,
  ...props
}) => {
  return (
    <textarea
      name={name}
      placeholder={placeholder}
      type={type}
      // register={register}
      value={value}
    //   onChange={handleChange}
      onChange={(e) => handleChange(e)}
      // required={required}
      {...(register? register(name): {})}
      area={required}
      {...props}
      className={
        (size?.length > 0 ? size : `w-full lg:w-96 `) +
        ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg`
      }
    />
  );
};

export default InputArea;
