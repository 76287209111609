import { createEmailSession, deleteSession, doctorAccountDetails, getAccount } from "../../utils/appwrite";

export const login = (email, password) => {
  return createEmailSession(email, password).then((res) => {
    localStorage.setItem("doci_doctor_user", JSON.stringify(res));
    return res;
  });
};

export const logout = () => {
  return deleteSession().then((res) => {
    localStorage.removeItem("doci_doctor_user");
    return res;
  });
};

export const getAccountDetails = () => {
  return getAccount().then((res) => {
    return res;
  });
};

export const getDoctorAccountDetails = (userID) => {
  return doctorAccountDetails(userID).then((res) => {
    return res;
  }).catch((error) => console.log("error===>", error));
};
