import { Client, Account, Databases, Query, ID } from "appwrite";

// const client = new Client();

export const client = new Client().setEndpoint(process.env.REACT_APP_API).setProject(process.env.REACT_APP_PROJECT_KEY)

client.setEndpoint(process.env.REACT_APP_API).setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const DoctorsCollection = "63627685685cfb4029e9";
const PatientCollection = "63a05d99b588183f3cf5";
const AppointmentCollection = "63a2f4305f99366d5b63";
const ChatsCollection = "63a45be654c2bf67c837";
const MessagesCollection = "63a45e3c9c1617f19dc5";
const ConsultationsCollection = "639317529c24aa63551e" 
const referralCollection = "640e64c0553d673e62b9"
const adminsCollection="6393175fb6f0cd0aa8d2"
const schedulerCollection = "6414289cbe2d598a5231";
const account = new Account(client);

const DB = new Databases(client);



function getAccount() {
  return account.get();
}

function acceptAppointment(doctorId, documentId) {
  return new Promise((resolve, reject) => {
    DB.updateDocument(DBID, AppointmentCollection, documentId, {
      // status: "accepted",
      doctorID: doctorId,
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });
}

function rejectAppointment(documentId) {
  return new Promise((resolve, reject) => {
    DB.updateDocument(DBID, AppointmentCollection, documentId, {
      // status: "pending",
      doctorID: "",
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });
}

function getAppointmentByID(id) {
  return new Promise((resolve, reject) => {
    DB.listDocuments(DBID, AppointmentCollection, [Query.equal("$id", id)])
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
}

function createEmailSession(email, password) {
  return account.createEmailSession(email, password);
}

function deleteSession() {
  console.log("deleteSession");
  return account.deleteSession("current");
}

function doctorAccountDetails(userID) {
  return DB.listDocuments(DBID, DoctorsCollection, [Query.equal("userID", userID)]);
}

function getAllPatients() {
  return DB.listDocuments(DBID, PatientCollection);
}

function getPatient(number) {
  console.log(number);
  return DB.listDocuments(DBID, PatientCollection, [Query.equal("phone_number", number)]);
}

function getAllAppointment() {
  let today = Date.now();
  return DB.listDocuments(DBID, AppointmentCollection, [Query.equal("status", "upcoming"), Query.limit(10), Query.orderAsc("date")]);
}

function getAllAppointment2() {
  return DB.listDocuments(DBID, AppointmentCollection, [Query.notEqual("status", "cancelled"),  Query.orderAsc("date")]);
}

function createUserAccount(email, password, name) {
  return account.create(ID.unique(), email, password, name);
}

function createChat(doctor_id, patient_ID, doctor_name, patient_name, last_message = "") {
  return DB.createDocument(DBID, ChatsCollection, {
    doctor_id,
    patient_ID,
    doctor_name,
    patient_name,
    last_message,
  });
}

function getChat(sender) {
  return DB.listDocuments(DBID, ChatsCollection, [Query.equal("doctor_id", sender)]);
}
function getMessages(chat_id) {
  return DB.listDocuments(DBID, MessagesCollection, [Query.equal("chatID", chat_id)][Query.orderAsc("message_sent")]);
}

function createMessage(message, sender_id, to_id, chatID, message_sent, isRead = false) {
  console.log(message, sender_id, to_id, chatID, message_sent, isRead);
  return DB.createDocument(DBID, MessagesCollection, ID.unique(), {
    message,
    sender_id,
    to_id,
    chatID,
    message_sent,
    isRead,
  });
}

function createDoctor(userID, first_name, last_name, designation, email) {
  return DB.createDocument(DBID, DoctorsCollection, ID.unique(), {
    userID,
    first_name,
    last_name,
    designation,
    email,
    // specialization,
  });
}


function getAllScheduler() {
  return DB.listDocuments(DBID, schedulerCollection);
}


function updateScheduler(id, data) {
  return DB.updateDocument(DBID, schedulerCollection, id, data);

  
}




// function createUserAccount(email, name, password) {
//   console.log(email, password, name);
//   return account.create(ID.unique(), email, password, name);
// }

// function createDoctor({ userID, first_name, last_name, designation, specialization, organization, email }) {
//   console.log(`new doctor email is ${email}`);
//   return DB.createDocument(DBID, DoctorsCollection, ID.unique(), {
//     userID,
//     first_name,
//     last_name,
//     designation,
//     email,
//     specialization,
//     organization,
//   });
// }

function createConsultation( 
  userID,
  complaints,
  diagnosis,
  prescribedrug,
  rtest,
  date,
  sensoryOne,
  sensoryTwo,
  sensoryNote,
  integumentaryOne,
  integumentaryTwo,
  integumentaryNote,
  circulatoryOne,
  circulatoryTwo,
  circulatoryNote,
  respiratoryOne,
  respiratoryTwo,
  respiratoryNote,
  dentalOne,
  dentalTwo,
  dentalNote,
  psychosocialOne,
  psychosocialTwo,
  psychosocialNote,
  nutritionOne,
  nutritionTwo,
  nutritionNote,
  gitOne,
  gitTwo,
  gitNote,
 ) {

  return DB.createDocument(DBID, ConsultationsCollection, ID.unique(), {
    userID,
    complaints,
    diagnosis,
    prescribedrug,
    rtest,
    date,
    sensoryOne,
    sensoryTwo,
    sensoryNote,
    integumentaryNote,
    circulatoryNote,
    respiratoryNote,
    dentalNote,
    psychosocialNote,
    nutritionNote,
    gitNote,
    integumentaryOne,
    integumentaryTwo,
    circulatoryOne,
    circulatoryTwo,
    respiratoryOne,
    respiratoryTwo,
    dentalOne,
    dentalTwo,
    psychosocialOne,
    psychosocialTwo,
    nutritionOne,
    nutritionTwo,
    gitOne,
    gitTwo,
  });
}


function getAllConsultation() {
  return DB.listDocuments(DBID, ConsultationsCollection);
}


function getAllDoctors() {
  return DB.listDocuments(DBID, DoctorsCollection);
}


function updateDoctor(id, data) {
  return DB.updateDocument(DBID, DoctorsCollection, id, data);
}


export {
  getAccount,
  getAllDoctors,
  updateDoctor,
  getAllScheduler,
  updateScheduler,
  createEmailSession,
  deleteSession,
  doctorAccountDetails,
  getAllPatients,
  getAllAppointment,
  getAppointmentByID,
getAllAppointment2,
  acceptAppointment,
rejectAppointment,
  getPatient,
  createUserAccount,
  createDoctor,
  createChat,
  getChat,
  createMessage,
  getMessages,
  createConsultation,
  getAllConsultation,
};

export default client;
