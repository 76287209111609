import React, { useState, useContext, useEffect } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";

//
import Layout from "../components/LoggedIn/Layout";
import Pagnation from "../components/FormComponent/Pagnation";

//
import { Bedicon, ViewProfileicon } from "../assets/SVG";
import { Arrowsvg } from "../assets/SVG";
import { Filtersvg } from "../assets/SVG";
import { BiSearchAlt } from "react-icons/bi";
import usePatient from "../hooks/usePatient";

import { DataContext } from "../contexts/Data/DataContext";
import moment from "moment";

const Patient = () => {
  const { allPatients, setAllPatients, getAllPatientsList } = usePatient();
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getAllPatientsList();
  },[])

  useEffect(() => {
    console.log('all patients are => ', allPatients)
    setSearchResult(allPatients)
  },[allPatients])


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredPatients = allPatients.filter(
      (patient) =>
        patient.first_name.toLowerCase().includes(value.toLowerCase()) ||
        patient.last_name.toLowerCase().includes(value.toLowerCase()) ||
        patient.email.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredPatients);
  };

  // const patients = searchValue.length > 0 ? searchResult : allPatients;




  const navigate = useNavigate();
  // const patients = searchValue.length > 0 ? searchResult : allPatients;
  return (
    <>
      <Layout>
        <div className="px-8 md:px-0">
          <div>
            <div className="flex mt-12 mb-6">
              <div
                className="mt-1 cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <Bedicon />
              </div>
              <div className="ml-5 mt-1">
                <Arrowsvg />
              </div>
              <div className="ml-3">Patients</div>
              {/* <div className='ml-5 mt-1'>
                <Arrowsvg />
              </div> */}
              {/* <div className='ml-3 w-50 bg-slate-50 rounded-md'>
                <button className='font-medium px-2 '>Basic beds</button>
              </div> */}
            </div>

            <div>
              <div className="text-3xl font-bold">Patients</div>
            </div>
          </div>

          {/* search */}
          <div className="flex justify-between">
            <label className="relative block mt-6 md:mt-8  w-full md:w-auto">
              <span className="sr-only ">Search</span>
              <div className="flex">
                <div className="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
                  <BiSearchAlt className="" />
                </div>

                <input
                  className="placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                  placeholder="Search"
                  type="text"
                  name="search"
                  // value={searchTerm}
                  value={searchValue}
                  onChange={handleSearchChange}
                 
                />
              </div>
            </label>
          </div>

          {/* table */}
          <div className="mt-12 md:pr-12">
            <div>
              <div className="w-full h-fit border border-navgray">
                <div className="overflow-x-auto w-full">
                  <table className="table w-full">
                    {/* <!-- head --> */}
                    <thead>
                      <tr className="bg-pink-200">
                        <th className="bg-pink-200">
                          <div className="bg-pink-200">#</div>
                        </th>
                        <th className="bg-pink-200">Name</th>
                        <th className="bg-pink-200">Age</th>
                        <th className="bg-pink-200">Sex</th>
                        <th className="bg-pink-200">Date of Birth</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <!-- row 1 --> */}
                      {searchResult?.map((elem, index) => {
                        let userImage =
                          elem.image ||
                          `https://ui-avatars.com/api/?name=${
                            elem?.first_name + " " + elem?.last_name
                          }&background=B21E14&color=fff`;
                        return (
                          <tr>
                            <th>
                              <div>{index + 1}</div>
                            </th>
                            <td>
                              <div className="flex items-center ">
                                <div className="avatar">
                                  <div className="mask mask-squircle w-12 h-12">
                                    <img src={userImage} alt="patient_image" />
                                  </div>
                                </div>

                                <div>
                                  <div className="font-bold">
                                    {elem?.first_name + " " + elem?.last_name}
                                  </div>
                                  <div className=" opacity-50 text-sm text-nextgray text-center ">
                                    {elem.patientstatus}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <th>
                              <div className="btn btn-ghost btn-xs">
                                {elem.age}
                              </div>
                            </th>
                            <td>{elem.gender}</td>
                            <td>
                              {moment(elem?.dob).format("DD-MM-YYYY")}
                              <br />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* open scheduler */}
          <Pagnation />
        </div>
      </Layout>
    </>
  );
};

export default Patient;
