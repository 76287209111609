import React from "react";

const FormInput = ({ name, placeholder, value, type, required, handleChange, size, disabled, ...props }) => {
  return (
    <input
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      value={value}
      onChange={(e) => handleChange(e)}
      required={required}
      {...props}
      className={
        (size?.length > 0 ? size : `w-full  `) +
        ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
      }
    />
  );
};

export default FormInput;
