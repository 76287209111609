import { getAllPatients, getAllAppointment, getPatient, getAllAppointment2 } from "../../utils/appwrite";

export const getPatients = () => {
  return getAllPatients().then((res) => {
    return res;
  });
};
export const getPatientData = (number) => {
  return getPatient(number).then((res) => {
    return res;
  });
};

export const getAppointment = () => {
  return getAllAppointment().then((res) => {
    return res;
  });
};
