import { useContext, useState } from "react";
import { UtilityContext } from "../contexts/Utility/UtilityContext";
import {
  createNewConsultation,
  getConsultation,
} from "../services/consultations/Consultation";
import useNotification from "./useNotification";

const useConsultations = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();

  const [allConsultations, setAllConsultations] = useState([]);
  const createConsultationInfo = async (data) => {
    setShowLoader(true);
    console.log(data);
    try {
      const createRes = await createNewConsultation({
        complaints: data.complaints,
        diagnosis: data.diagnosis,
        prescribedrug: data.prescribedrug,
        rtest: data.rtest,
        date: data.date,
        sensoryOne: data.sensoryOne,
        sensoryTwo: data.sensoryTwo,
        sensoryNote: data.sensoryNote,
        integumentaryOne: data.integumentaryOne,
        integumentaryTwo: data.integumentaryTwo,
        integumentaryNote: data.integumentaryNote,
        circulatoryOne: data.circulatoryOne,
        circulatoryTwo: data.circulatoryTwo,
        circulatoryNote: data.circulatoryNote,
        respiratoryOne: data.respiratoryOne,
        respiratoryTwo: data.respiratoryTwo,
        respiratoryNote: data.respiratoryNote,
        dentalOne: data.dentalOne,
        dentalTwo: data.dentalTwo,
        dentalNote: data.dentalNote,
        psychosocialOne: data.psychosocialOne,
        psychosocialTwo: data.psychosocialTwo,
        psychosocialNote: data.psychosocialNote,
        nutritionOne: data.nutritionOne,
        nutritionTwo: data.nutritionTwo,
        nutritionNote: data.nutritionNote,
        gitOne: data.gitOne,
        gitTwo: data.gitTwo,
        gitNote: data.gitNote,
      });
      console.log({ createRes });
      handleSuccess("Saved");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };
    
  const getAllConsultationList = async () => {
    setShowLoader(true)
    const consultations = await getConsultation()
    console.log({consultations })
    setAllConsultations(consultations.documents)
    setShowLoader(false);
  }  
    
  return {
      allConsultations,
      getAllConsultationList,
    createConsultationInfo,
  };
};

export default useConsultations;
