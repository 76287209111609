import React, { useState } from "react";
import logo from "../components/photos/logo.png";
import Frame from "../components/photos/frame.png"
import Photo from "../components/photos/nextillustration.png";
import Doctorimg from "../components/photos/doctorimg.jpg";
import { Link, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = (props) => {
  const { loginUser } = useAuth();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [show, setShow] = useState(false);

  const submitForm = async (data) => {
    console.log(data);
    loginUser(data.email, data.password).then((res) => {
      navigate("/");
    });
  };

  return (
    <div className="h-full overflow-y-hidden">
      <div className="lg:flex h-full ">
        {/* left screen */}
        <div className="h-screen bg-mygray lg:w-[258%] lg:block hidden relative">
          {/* logo */}
          <div className="absolute w-96">
            <img src={Frame} alt="" />
          </div>

          {/*  */}
          <div className="">
            <div className="h-screen">
              <img
                style={{}}
                src={Doctorimg}
                alt=""
                srcSet=""
                className="h-screen"
              />
            </div>
          </div>
        </div>

        {/* right screen */}
        <div className="w-full sm:w-full lg:1/5  h-full sm:text-center  lg:text-start px-12 md:px-8 lg:px-12">
          {/* logo */}
          <div className="flex justify-center pt-24">
            <div className="w-24 ">
              <img src={logo} alt="" />
            </div>
          </div>

          <div className=" pt- sm:pt- md:pt-6 lg:pt-  ">
            <h1 className="mb-one font-bold">
              <span className="text-[18px] text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
                Welcome back to DociHealthcare!
              </span>
              👋🏻
            </h1>
          </div>

          <h2 className="text-nextgray text-[14px] font-medium ">
            Please sign-up to acess your account and get started
          </h2>

          {/* form */}
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="">
              <label
                htmlFor="email"
                className="block text-sm  font-medium text-nextgray"
              >
                Email Address
              </label>
              <input
                {...register("email")}
                type="email"
                name="email"
                placeholder="someone@example.com"
                className="mt-1 mb-six block w-full h-14 rounded-md text-left pl-2 text-sm  border-bordergray border sm:text-sm"
              />
              <br />

              <div className="mt-2 relative">
                <div className="flex w-full justify-between">
                  <p className="text-[#666666] text-[14px]">Password</p>
                  <p
                    className="text-[#B21E14] text-[14px] cursor-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </p>
                </div>
                <input
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="***********"
                  width="w-[350px]"
                  height="h-[45px]"
                  {...register("password")}
                  className="mt-1 block w-full h-14 rounded-md text-left pl-2 text-sm  border-bordergray border    sm:text-sm"
                />
                <div
                  className="absolute top-12 right-2 text-[18px]"
                  onClick={() => setShow((prev) => !prev)}
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
                <p className="text-[13px] mt-2 text-red-400">
                  {errors.password?.message}
                </p>

                <br />
                <input type="checkbox" />
                <span className="text-nextgray pl-6 font-medium">
                  Remember me
                </span>
              </div>
            </div>
            <br />
            <button
              type="submit"
              className=" bg-gradient-to-r from-red-500  via-red-600 to-red-700 inline-flex justify-center w-full h-12 rounded-lg text-white py-3"
            >
              Login
            </button>
            <br />
            <div className="text-center pt-4">
              <h4 className="inline">New on our platform?</h4>
              <Link to="/register">
                <h5 className="ml-4 inline text-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
                  Create an account
                </h5>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
