import React, { useState, useContext } from "react";
import Photo from "../photos/frame.png";
import Logo from "../photos/logo.png";
import Ellipse from "../photos/ellipse.png";
import { LogoSvg } from "../../assets/SVG";

//
import { BiSearchAlt, BiArrowBack } from "react-icons/bi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import ReactImageFallback from "react-image-fallback";
import defaultImage from "../photos/default_user.png";
//
import Dropdown from "../FormComponent/Dropdown";

// import SideNav from "./Slidesidebar";

//
import { showContext } from "../../contexts/Form/ShowContext";
import { viewSearchContext } from "../../contexts/Form/ViewSearch";

import useNotification from "../../hooks/useNotification";
import { UserContext } from "../../contexts/User/UserContext";
import { Link } from "react-router-dom";

const Nextheader = ({ showSidebar, toggleSidebar }) => {
  const { doctorDetails } = useContext(UserContext);

  const { isShown, setIsShown } = useContext(showContext);
  const { activesearch, setActiveSearch } = useContext(viewSearchContext);

  let userImage =
    doctorDetails?.image ||
    `https://ui-avatars.com/api/?name=${
      doctorDetails?.first_name + " " + doctorDetails?.last_name
    }&background=B21E14&color=fff`;

  return (
    <div className="">
      <div className=" header-container relative z-50">
        <div className="flex justify-between items-center pl-8 bg-navbg drop-shadow-sm ">
          <div className="flex items-center">
            <label
              htmlFor="my-drawer"
              className="drawer-button flex-1 lg:hidden"
            >
              <GiHamburgerMenu className="text-lg cursor-pointer" />
            </label>
            {/* logo */}
            <div className="">
              <Link to="/">
                <img
                  src={Photo}
                  alt=""
                  srcSet=""
                  className="max-w-xs hidden md:block"
                />
                <img
                  src={Logo}
                  alt=""
                  srcSet=""
                  className="md:hidden"
                  style={{ width: "70px" }}
                />
              </Link>
            </div>
          </div>

          {/* small screen search */}
          {/* {activesearch ? (
            <div className="absolute z-100 bg-white w-full  border block md:hidden ">
              <label className=" w-full   ">
                <span className="sr-only ">Search</span>

                <div className="flex">
                  <div
                    onClick={() => setActiveSearch(false)}
                    className="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10"
                  >
                    <BiArrowBack size={20} className="cursor-pointer" />
                  </div>
                  <input
                    className="px-4 placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                    placeholder="Search"
                    type="text"
                    name="search"
                  />
                </div>
              </label>
            </div>
          ) : (
            ""
          )} */}

          {/* Large screen search */}
          {/* <label className='relative  hidden md:block w-1/4'>
            <span className='sr-only '>Search</span>
            <div className='flex'>
              <div className='absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10'>
                <BiSearchAlt className='' />
              </div>
              <input
                className=' placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm'
                placeholder='Search'
                type='text'
                name='search'
              />
            </div>
          </label> */}

          <div className="flex justify-center relative-none md:relative  z-12">
            <div className="flex mr-12 items-center justify-center ">
              {/* search icon */}
              

              {/* notification */}
              <div className="inline pr-2">
                <IoMdNotificationsOutline size={28} />
              </div>
              <div className="inline w-12 border-none hover:border-blue-600">
                {/* <img
                  onClick={() => setIsShown(!isShown)}
                  src={Ellipse}
                  alt=""
                /> */}
                <div className="avatar">
                  <div className="mask mask-squircle w-10 h-10">
                    <ReactImageFallback
                      onClick={() => setIsShown(!isShown)}
                      src={userImage}
                      fallbackImage={defaultImage}
                      initialImage={defaultImage}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-[10px] hidden md:block">
                <div className="text-sidetextbg">
                  Dr.{" "}
                  {doctorDetails?.first_name + " " + doctorDetails?.last_name}
                </div>
                <div className="text-grayon">{doctorDetails?.email}</div>
              </div>
            </div>

            {/* Profile drop down */}
            <Dropdown className="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nextheader;
