import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import usePatient from "../../hooks/usePatient";

const Headings = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {setPatient} = usePatient()

  return (
    <div className=''>
      <div className='text-end pr-10 md:pr-20 text-[12px] md:text-[16px] mt-0  md:mt-8 pb-12'>
      <button className='p-2 text-sm h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg-nextgray hover:text-white rounded-md'>
              <div className='items-center justify-center'>
            <div  onClick={() => {
              setPatient(null)
              console.log('location ->',location.pathname)
              if(location.pathname === '/patient-information') window.location.reload()
              else navigate('/patient-information')
              }} className='font-bold cursor-pointer'>
                  Start New Consultation
                </div>
              </div>
            </button>
      </div>

      <div className='flex  text-grayon  mb-12  px-4 md:px-0 text-center md:text-start'>
        <Link
          to='/patient-information'
          className={`text-[12px] md:text-[18px]  pr- md:pr-26  lg:pr-44 ${location.pathname === "/patient-information" ? "activeTextColor" : ""} `}
        >
          Patient Information
        </Link>
        <Link
          to='/medical-history'
          className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname === "/medical-history" ? "activeTextColor" : ""} `}
        >
          Medical History
        </Link>
        <Link
          to='/consultation'
          className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname === "/consultation" ? "activeTextColor" : ""} `}
        >
          Doctor’s Consultation
        </Link>
        <Link
          to='/referral'
          className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname === "/referral" ? "activeTextColor" : ""} `}
        >
          Referrals
        </Link>
      </div>
    </div>
  );
};

export default Headings;
