import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { UserProvider } from "./contexts/User/UserContext";
import { UtilityProvider } from "./contexts/Utility/UtilityContext";
import { DataProvider } from "./contexts/Data/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UtilityProvider>
        <UserProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </UserProvider>
      </UtilityProvider>
    </BrowserRouter>
  </React.StrictMode>
);
