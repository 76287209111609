import { createDoctor, createUserAccount, getAllDoctors, updateDoctor} from "../../utils/appwrite";

export const createNewDoctorUserAccount = (email, password, first_name, last_name) => {
  const name = `${first_name} ${last_name}`;
  return createUserAccount(email, password, name).then((res) => {
    return res;
  });
};

export const createNewDoctor = (userID, first_name, last_name, designation, specialization, email) => {
  return createDoctor(userID, first_name, last_name, designation, specialization, email).then((res) => {
    return res;
  });
};


export const createNewDoctorUserAccountt = (email, password, first_name, last_name) => {
  const name = `${first_name} ${last_name}`;
  return createUserAccount(email, password, name)
};




export const createNewDoctorr = (userID, first_name, last_name, designation,  email) => {
  return createDoctor(userID, first_name, last_name, designation, email)
};


export const getDoctor = () => {
  return getAllDoctors().then((res) => {
    return res;
  });
};



export const updateDoctorData = (id,data) => {
  return updateDoctor(id,data).then((res) => {
    return res;
  });
};